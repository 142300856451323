import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {CmsService} from '../../services/cms.service';
import {map, tap} from 'rxjs/operators';
import { ChangeDetectorRef } from '@angular/core';
import {filter} from 'rxjs/operators';

/*import {log} from 'util';*/
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {SearchService} from "../../services/search.service";
import {LoginService} from "../../services/login.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  pages = [];
  pages$;
  lang;


  categories$

  currentLang




  constructor(private translateService: TranslateService,
              private cmsService: CmsService,
              private ref: ChangeDetectorRef,
              public route: Router,
              public loginService: LoginService,
              public searchService: SearchService) {
    this.pages$ = this.cmsService.getHtmlPages();

  }

  menuOpen = false;
  langSelector = 'en';


  login() {
    this.route.navigate(['/login']);
  }



  openMenu() {
    this.menuOpen = !this.menuOpen;
  }
  changeLang(lang: string) {
    this.translateService.use(lang);
    this.langSelector = lang;
    this.currentLang = lang
    this.cmsService.selectedLang = lang
    this.ref.markForCheck();
  }

  async ngOnInit() {
    this.route.events.pipe(
      filter(event => event instanceof NavigationEnd),
    )
      .subscribe(event => {
        this.actualUrl = this.route.url
      });

    //if token is present in local storage, get user profile
    if(this.loginService.getToken() && !this.loginService.isLoggedIn){
      console.log('token', this.loginService.getToken())
      this.loginService.token = this.loginService.getToken()
      this.loginService.getMe(this.loginService.getToken())
    }

    //get current language
    this.currentLang = this.translateService.currentLang;

    this.categories$ = this.cmsService.getCategories(this.loginService.token).pipe(map(
      data => {
        return data
      }
    ));


  }

  actualUrl:any

}


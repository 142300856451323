import { BrowserModule } from '@angular/platform-browser';
import {NgModule, ApplicationRef, LOCALE_ID} from '@angular/core';

import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';

import { MomentModule } from 'ngx-moment';


import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HomeComponent } from './pages/home/home.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { MapsListComponent } from './components/maps-list-NOT-USED/maps-list.component';
import { DocsListComponent } from './components/docs-list-NOT-USED/docs-list.component';
import { SingleCategoryComponent } from './pages/single-category/single-category.component';
import { CategoryComponent } from './pages/category/category.component';
import { DocDetailsComponent } from './pages/doc-details/doc-details.component';
import { CategoriesBoxComponent } from './components/categories-box/categories-box.component';
import { GisContainerComponent } from './pages/gis-container/gis-container.component';
import { MapComponent } from './components/map/map.component';
import { MainFooterComponent } from './components/main-footer/main-footer.component';
import {HttpClientModule, HttpClient} from '@angular/common/http';
import {NgOrderByPipeModule, NgRangePipeModule, NgTakeUntilPipeModule} from 'ngx2-pipes';
import { LoginComponent } from './components/login/login.component';
import {environment} from '@environment';


import { ResourcesListComponent } from './components/resources-list/resources-list.component';
import { AngularMultiSelectModule } from '@khajegan/angular2-multiselect-dropdown';
import { FormsModule } from '@angular/forms';
import { ChartDialogComponent } from './components/chart-dialog/chart-dialog.component';
import {NgbAlert, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { SinglePageComponent } from './pages/single-page/single-page.component';
import { FilterResourcesPipe } from './pipes/filter-resources.pipe';
import { ResourcesFilterComponent } from './components/resources-filter/resources-filter.component';
import { ChartManagerComponent } from './components/charts/chart-manager/chart-manager.component';
import { PieChartComponent } from './components/charts/pie-chart/pie-chart.component';
import { StackedbarChartComponent } from './components/charts/stackedbar-chart/stackedbar-chart.component';
import { HorizontalBarChartComponent } from './components/charts/horizontal-bar-chart/horizontal-bar-chart.component';
import { LineChartComponent } from './components/charts/line-chart/line-chart.component';
import { GenericChartComponent } from './components/charts/generic-chart/generic-chart.component';
import { WebgisComponent } from './components/webgis/webgis.component';
import {LinkComponent, SafePipe} from './components/link/link.component';
import { DocumentComponent } from './components/document/document.component';
import { VideoDialogComponent } from './components/video-dialog/video-dialog.component';
import { ResourcesLastComponent } from './components/resources-last/resources-last.component';
import { BaseLayerChooserComponent } from './components/base-layer-chooser/base-layer-chooser.component';
import { InfoPopUpComponentComponent } from './components/info-pop-up-component/info-pop-up-component.component';
import { ReportWizardComponent } from './components/report-wizard/report-wizard.component';
import { StoryComponent } from './components/story/story.component';
import { LayerTreeComponent } from './components/layer-tree/layer-tree.component';
import { LayerGroupChooserComponent } from './components/layer-group-chooser/layer-group-chooser.component';
import { MagicSearchComponent } from './components/magic-search/magic-search.component';
import { ResourcesSearchComponent } from './components/resources-search/resources-search.component';
import { FilterSearchPipe } from './pipes/filter-search.pipe';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { PrintDashboardComponent } from './components/print-dashboard/print-dashboard.component';
import { CookiePageComponent } from './pages/cookie-page/cookie-page.component';
import { VideoTutorialComponent } from './pages/video-tutorial/video-tutorial.component';
import { CustomPrintCartComponent } from './components/custom-print-cart/custom-print-cart.component';
import { SearchResultComponent } from './pages/search-result/search-result.component';
import {CustomPrintPageComponent} from "./components/custom-print-page/custom-print-page.component";

import { registerLocaleData } from '@angular/common';
import localeAr from '@angular/common/locales/ar';
import { ShareItemComponent } from './components/share-item/share-item.component';

registerLocaleData(localeAr);



@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        HeaderComponent,
        FooterComponent,
        MapsListComponent,
        DocsListComponent,
        SingleCategoryComponent,
        CategoryComponent,
        DocDetailsComponent,
        CategoriesBoxComponent,
        GisContainerComponent,
        MapComponent,
        MainFooterComponent,
        LoginComponent,
        MainFooterComponent,
        ResourcesListComponent,
        ChartDialogComponent,
        DashboardComponent,
        SinglePageComponent,
        FilterResourcesPipe,
        ResourcesFilterComponent,
        ChartManagerComponent,
        PieChartComponent,
        StackedbarChartComponent,
        HorizontalBarChartComponent,
        LineChartComponent,
        GenericChartComponent,
        WebgisComponent,
        LinkComponent,
        DocumentComponent,
        VideoDialogComponent,
        ResourcesLastComponent,
        SafePipe,
        BaseLayerChooserComponent,
        ReportWizardComponent,
        InfoPopUpComponentComponent,
        StoryComponent,
        LayerTreeComponent,
        LayerGroupChooserComponent,
        MagicSearchComponent,
        ResourcesSearchComponent,
        FilterSearchPipe,
        PrintDashboardComponent,
        CookiePageComponent,
        VideoTutorialComponent,
        CustomPrintCartComponent,
        SearchResultComponent,
        CustomPrintPageComponent,
        ShareItemComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        NgOrderByPipeModule,
        AngularMultiSelectModule,
        FormsModule,
        NgbModule,
        MomentModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        NgTakeUntilPipeModule,
        NgRangePipeModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        BrowserAnimationsModule
    ],
    providers: [
        { provide: LOCALE_ID, useValue: 'ar' }
    ],
  bootstrap: [AppComponent]
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

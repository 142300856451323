// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.resource-list-card img {
  height: 55px;
}

.fa-info-circle, .fa-calendar-alt {
  margin-right: 0.5em;
}

::ng-deep .inverted-layout .search-result {
  direction: rtl;
  text-align: right;
}
::ng-deep .inverted-layout .fa-info-circle, ::ng-deep .inverted-layout .fa-calendar-alt {
  margin-right: 0em;
  margin-left: 0.5em;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/search-result/search-result.component.scss"],"names":[],"mappings":"AACE;EACE,YAAA;AAAJ;;AAIA;EACE,mBAAA;AADF;;AAMI;EACE,cAAA;EACA,iBAAA;AAHN;AAKI;EACE,iBAAA;EACA,kBAAA;AAHN","sourcesContent":[".resource-list-card {\n  img {\n    height: 55px;\n  }\n}\n\n.fa-info-circle, .fa-calendar-alt {\n  margin-right: 0.5em;\n}\n\n::ng-deep {\n  .inverted-layout {\n    .search-result {\n      direction: rtl;\n      text-align: right;\n    }\n    .fa-info-circle, .fa-calendar-alt {\n      margin-right: 0em;\n      margin-left: 0.5em;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import {Component, Input, OnInit} from '@angular/core';
import {CmsService} from '../../services/cms.service';
import { v4 as uuidv4 } from 'uuid';
import * as L from 'leaflet';
import {tap} from 'rxjs/operators';


@Component({
  selector: 'app-webgis',
  templateUrl: './webgis.component.html',
  styleUrls: ['./webgis.component.scss']
})
export class WebgisComponent implements OnInit {

  @Input() mapResourceId;
  @Input() cssClassName: string;


  uid;
  webgisConfiguration$;
  webgisConfiguration;
  Map: L.Map;

  baseLayer: L.TileLayer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    attribution: '© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
  });


  constructor(private cmsService: CmsService) {
    this.uid = uuidv4();

    console.log('load webgis component');
  }


  ngOnInit() {

    this.cmsService.getWebgisMap(this.mapResourceId);

    this.webgisConfiguration$ = this.cmsService.getWebgisById(this.mapResourceId).pipe(tap(
        data => {
          this.webgisConfiguration = data;
          this.setBaseMap();
        }
    ));
  }

  setBaseMap() {


    const viewerOptions: L.MapOptions = {
      layers: [this.baseLayer],
    };
    this.Map = L.map(this.uid, viewerOptions);

    this.Map.fitBounds([[15.235772090429855, -80.44287109375001], [21.747092, -65.345362]]);

    this.Map.setZoom(8);
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit() {

  }

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterSearch',
  pure: false
})
export class FilterSearchPipe implements PipeTransform {

  transform(items: any, ...args: any): any {
    if (!items || !args) {
      return items;
    }

    return items.filter(x => x.name);
  }

  // this.filteredResources = this.filterResourcesPipe.transform(this.resources, this.filterSettings);

}

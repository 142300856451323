import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterResources',
  pure: false
})
export class FilterResourcesPipe implements PipeTransform {

  transform(items: any, args: any): any {
    if (!items || !args) {
      return items;
    }
    // {searchText:searchText, isMap :this.isMap, isDocument: this.isDocument, isChart: this.isChart, selectedItems: this.selectedItems}
    items = items.filter((item) => {
      if ( args.isMap  && args.isDocument && args.isChart && args.isLink && args.isStory) return  true;
      // tslint:disable-next-line:max-line-length
      return ((args.isMap === true && item.resource_type === 'webgis') || (args.isDocument === true && item.resource_type === 'document') || (args.isChart === true && item.resource_type === 'graph') || (args.isLink === true && item.resource_type === 'link') || (args.isStory === true && item.resource_type === 'story')) ? true : false;
    });


    if ( args.searchText !== '') {
      // tslint:disable-next-line:max-line-length
      items = items.filter((item) => {
        // tslint:disable-next-line:max-line-length
        return (item.name_en.toUpperCase().indexOf(args.searchText.toUpperCase()) > -1 || item.name_ar.toUpperCase().indexOf(args.searchText.toUpperCase()) > -1);
      });
    }

    if (args.selectedItems.length > 0) {
      items = items.filter(item => {
          let b = false;
          args.selectedItems.forEach(v => {
            if (!b) {
              {
                b = (item.topics.indexOf(v.name_en) > -1);
              }
            }
          });
          return b;
      });
    }

    if( args.subIndicatorSelected.length > 0) {
      items = items.filter(item => {
        let b = false;
        args.subIndicatorSelected.forEach(v => {
          if (!b) {
            {
              b = (item.topics.indexOf(v.name_en) > -1);
            }
          }
        });
        return b;
      });
    }


    // SDGS
    if( args.sdgSelected.length > 0) {
      items = items.filter(item => {
        let b = false;
        args.sdgSelected.forEach(v => {
          if (!b) {
            {
              b = (item.sdgs.indexOf(v.name) > -1);
            }
          }
        });
        return b;
      });
    }

    if ( items.length > args.eachPage) {
        // item.let
    }


    // console.log(items, args);
    return  items;
  }

}

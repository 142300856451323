import { Component, OnInit } from '@angular/core';
import {CmsService} from '../../services/cms.service';
import {map} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {LoginService} from "../../services/login.service";

@Component({
  selector: 'app-categories-box',
  templateUrl: './categories-box.component.html',
  styleUrls: ['./categories-box.component.scss']
})
export class CategoriesBoxComponent implements OnInit {

  categories$ = this.cmsService.getCategories(this.loginService.token).pipe(map(
      data => {
        //return data.filter(cat => cat.id !== 9);
        return data
      }
  ));

    currentLang;

  constructor(private cmsService: CmsService, private translateService: TranslateService, private loginService: LoginService) { }

  ngOnInit() {
      this.currentLang = this.translateService.currentLang;
      this.translateService.onLangChange.subscribe(lang => {
          this.currentLang = lang.lang;
      });
  }

}

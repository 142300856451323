import {Component, OnInit} from '@angular/core';
import {CmsService} from '../../services/cms.service';
import {map, switchMap, tap, timeout} from 'rxjs/operators';
import {SerieService} from '../../services/serie.service';
import {ActivatedRoute, Router} from '@angular/router';
import domtoimage from 'dom-to-image';
import {ConfigService} from '../../services/config.service';
import {TranslateService} from '@ngx-translate/core';
import {CustomPrintService} from "../../services/custom-print.service";
import {LoginService} from "../../services/login.service";

@Component({
  selector: 'custom-print-page',
  templateUrl: './custom-print-page.component.html',
  styleUrls: ['./custom-print-page.component.scss']
})
export class CustomPrintPageComponent implements OnInit {

  hideShowSidebar = true;

  selectedCategory;
  category;
  resources;

  isPrintingDashboard = false;


  currentLang = 'en';
  loadingStatus = {};


  // DEMO PER FILTRO

  selectedItems = [];

  loader: boolean = false;


  print(id) {
    this.isPrintingDashboard = true;
    domtoimage.toJpeg(document.getElementById(id), {quality: 0.95})
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.download = 'dashboard.jpeg';
        link.href = dataUrl;
        link.click();
        this.isPrintingDashboard = false;
      });
  }

  exportData(resource) {


    this.cmsService.getFullResourceByTypeAndId('graph', resource.id).pipe(
      // @ts-ignore
      switchMap(fullResource => this.serieService.getSerie(fullResource.configuration.serie)),
      map(data => {

        let csvContent = 'data:text/csv;charset=utf-8,';
        csvContent += data.labels.join(',') + '\n';

        if (Object.keys(data.values).length > 0 && !Array.isArray(data.values)) {

          Object.keys(data.values).map(val => {
            csvContent += data.values[val].join(',') + '\n';
          });

        } else {
          csvContent += data.values.join(',');
        }
        const encodedUri = encodeURI(csvContent);
        window.open(encodedUri);
      })
    ).subscribe();
  }



  loadPreselectedResources() {
    setTimeout(()=> {
        if (!this.loader) {
          console.log('STAMPO')
          window.print();
          this.router.navigate(['/dashboard'])
        } else {
          console.log('RIPROVO FRA UN SECONDO')
          setTimeout(()=>this.loadPreselectedResources(), 1000)
        }
    },3000)

  };

  // tslint:disable-next-line:max-line-length
  constructor(private cmsService: CmsService,
              private serieService: SerieService,
              private activatedRoute: ActivatedRoute,
              private configService: ConfigService,
              private loginService: LoginService,
              private translateService: TranslateService,
              public customPrintService: CustomPrintService,
              private router : Router
  ) {
    this.router = router;
  }

  ngOnInit() {
    this.currentLang = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe(lang => {
      this.currentLang = lang.lang;
    });

    this.activatedRoute.queryParams.subscribe(params => {

      if (params && params.lang) {
        this.currentLang = params.lang;
        // this.loadPreselectedResources();
      }
    });

    this.cmsService.getCategories(this.loginService.token).subscribe(categories => {
      this.selectedCategory = categories[0];
    })

    this.loadPreselectedResources()

    console.log(this.customPrintService.itemsToPrint)

  }


  handleLoading($event, mapId){
    this.loadingStatus[mapId] = $event;
    const loadingStatuses = Object.keys(this.loadingStatus).map(k => this.loadingStatus[k]).filter(v => v);
    this.loader = loadingStatuses.length>0;

  }


}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CmsService} from '../../services/cms.service';
import {LayersGroup} from '../../interfaces/webgis';

@Component({
  selector: 'app-layer-group-chooser',
  templateUrl: './layer-group-chooser.component.html',
  styleUrls: ['./layer-group-chooser.component.scss']
})
export class LayerGroupChooserComponent implements OnInit {

  @Input() webgisConfiguration;

  @Output() selected = new EventEmitter();

  objectKeys = Object.keys;

  layersGroup$;

  constructor(public activeModal: NgbActiveModal, public cmsService: CmsService ) {
    this.layersGroup$ = this.cmsService.getLayersGroup();
  }

  ngOnInit() {
  }

}

import {Component, OnInit} from '@angular/core';
import {CmsService} from '../../services/cms.service';
import {map, switchMap, tap, timeout} from 'rxjs/operators';
import {SerieService} from '../../services/serie.service';
import {ActivatedRoute, Router} from '@angular/router';
import domtoimage from 'dom-to-image';
import {ConfigService} from '../../services/config.service';
import {TranslateService} from '@ngx-translate/core';
import {LoginService} from "../../services/login.service";

@Component({
  selector: 'app-print-dashboard',
  templateUrl: './print-dashboard.component.html',
  styleUrls: ['./print-dashboard.component.scss']
})
export class PrintDashboardComponent implements OnInit {

  hideShowSidebar = true;

  selectedCategory;
  category;
  resources$;
  resources;
  categories$;

  isPrintingDashboard = false;
  preselectedResources = [];
  preselectedCategory = '';

  currentLang = 'en';

  loader: boolean = false;
  loadingStatus = {};


  userRoles;

  tileExpanded = false;

  // DEMO PER FILTRO

  selectedItems = [];

  // closeBB
  closeBB(elementID) {
    document.getElementById(elementID).classList.add('d-none');
  }

  toggleSidebar() {
    this.hideShowSidebar = !this.hideShowSidebar;
  }

  // hideshow le info
  toggleInfo(elementID) {
    if (document.getElementById(elementID).classList.contains('hideInfo')) {
      document.getElementById(elementID).classList.remove('hideInfo');
      document.getElementById(elementID).classList.add('showInfo');
    } else {
      document.getElementById(elementID).classList.remove('showInfo');
      document.getElementById(elementID).classList.add('hideInfo');
    }
  }

  // espande contrae la tile

  toggleExpand(elementID) {
    this.tileExpanded = !this.tileExpanded;
    if (document.getElementById(elementID).classList.contains('col-lg-6')) {
      document.getElementById(elementID).classList.remove('col-lg-6');
      document.getElementById(elementID).classList.add('col-12');
    } else {
      document.getElementById(elementID).classList.remove('col-12');
      document.getElementById(elementID).classList.add('col-lg-6');
    }
  }

  selectCategory(category) {
    this.selectedCategory = category;
  }

  canPrintDashboard() {
    return this.configService.canPrintDashboard();
  }

  print(id) {
    this.isPrintingDashboard = true;
    domtoimage.toJpeg(document.getElementById(id), {quality: 0.95})
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.download = 'dashboard.jpeg';
        link.href = dataUrl;
        link.click();
        this.isPrintingDashboard = false;
      });
  }

  exportData(resource) {

    // this.serieService.exportData(resource).subscribe();

    this.cmsService.getFullResourceByTypeAndId('graph', resource.id).pipe(
      // @ts-ignore
      switchMap(fullResource => this.serieService.getSerie(fullResource.configuration.serie)),
      map(data => {

        let csvContent = 'data:text/csv;charset=utf-8,';
        csvContent += data.labels.join(',') + '\n';

        if (Object.keys(data.values).length > 0 && !Array.isArray(data.values)) {

          Object.keys(data.values).map(val => {
            csvContent += data.values[val].join(',') + '\n';
          });

        } else {
          csvContent += data.values.join(',');
        }
        const encodedUri = encodeURI(csvContent);
        window.open(encodedUri);
      })
    ).subscribe();
  }

  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  loadPreselectedResources(resources?) {

    this.resources = resources.filter((resource) => {
      return (this.preselectedResources.indexOf(resource.resource_type + ';' + resource.id) > -1);
    });
    this.chekAndPrint()
  };

  chekAndPrint(){
    setTimeout(()=> {
      if (!this.loader) {
        console.log('STAMPO')
        window.print();
        this.router.navigate(['/dashboard'])
      } else {
        console.log('RIPROVO FRA UN SECONDO')
        setTimeout(()=>this.chekAndPrint(), 1000)
      }
    },3000)
  }

  // tslint:disable-next-line:max-line-length
  constructor(private cmsService: CmsService,
              private serieService: SerieService,
              private activatedRoute: ActivatedRoute,
              private configService: ConfigService,
              private translateService: TranslateService,
              private router : Router,
              private loginService: LoginService
  ) {
    this.router = router;
  }

  ngOnInit() {
    this.currentLang = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe(lang => {
      this.currentLang = lang.lang;
    });

    this.activatedRoute.queryParams.subscribe(params => {

      if (params && params.lang) {
        this.currentLang = params.lang;
        // this.loadPreselectedResources();
      }
      if (params && params.preselected_resources) {

        this.preselectedResources = params.preselected_resources.split(',');
      }

      if (params && params.category) {

        // this.selectedCategory = parseInt(params.category, 10);
        this.preselectedCategory = params.category;

        this.categories$ = this.cmsService.getCategories(this.loginService.token).pipe(
            tap((categories) => {
              // @ts-ignore
              this.selectedCategory = categories.find(cat => cat.id.toString() === this.preselectedCategory);
            })
        );
      }
    });

  }

  handleLoading($event, mapId){
    this.loadingStatus[mapId] = $event;
    const loadingStatuses = Object.keys(this.loadingStatus).map(k => this.loadingStatus[k]).filter(v => v);
    this.loader = loadingStatuses.length>0;

  }


}

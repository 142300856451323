import { Component, OnInit } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {LoginService} from "../../services/login.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {


  constructor(private translateService: TranslateService, public loginService: LoginService, private route: Router) { }


  login() {
    this.route.navigate(['/login']);
  }

  async ngOnInit() {
  }
  getCurrentLang() {
    return this.translateService.currentLang;
  }

}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `td, th {
  padding: 5px;
}

th {
  font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/cookie-page/cookie-page.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AACA;EACE,gBAAA;AAEF","sourcesContent":["td, th {\n  padding: 5px;\n}\nth {\n  font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

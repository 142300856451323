import {Component, ComponentFactoryResolver, Injector, Input, OnInit} from '@angular/core';
import {CmsService} from '../../services/cms.service';
import {ApiService} from '../../services/api.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-story',
  templateUrl: './story.component.html',
  styleUrls: ['./story.component.scss']
})
export class StoryComponent implements OnInit {

  @Input() storyResourceId;
  @Input() storyResource;
  @Input() selectedCategory;

  story;
  currentLang = 'en' ;


  constructor(private cmsService: CmsService, private apiService: ApiService, private translateService: TranslateService) {
    this.story = this.storyResource;

  }

  ngOnInit() {
    this.story = this.storyResource;

    this.currentLang = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe(lang => {
      this.currentLang = lang.lang;
    });
  }
}



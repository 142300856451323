import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {forkJoin, from, of} from 'rxjs';
import {map, mergeMap, tap} from 'rxjs/operators';
/*import {fromArray} from 'rxjs/internal/observable/fromArray';*/
import {WebgisMap} from '../../interfaces/webgis';

@Component({
  selector: 'app-info-pop-up-component',
  templateUrl: './info-pop-up-component.component.html',
  styleUrls: ['./info-pop-up-component.component.scss']
})
export class InfoPopUpComponentComponent implements OnInit {

  @Input() webGisConfiguration: WebgisMap;
  @Input() event: any;
  @Output() callBack = new EventEmitter();

  res$;


  constructor(private http: HttpClient, private ref: ChangeDetectorRef) { }

  ngOnInit() {

   if ( this.webGisConfiguration) {



      const res = Object.keys(this.webGisConfiguration.layers).map(key => {
        return this.webGisConfiguration.layers[key]
            .filter(layer => layer.layerInstance.getOpacity() > 0 && layer.layerInstance.hasInfoData()) // filter visible layer
            .map(layer => {
              return layer.layerInstance.infoData(this.event, layer.layerInstance);
        });
      });
      // @ts-ignore
      this.res$ = forkJoin(res.flat()).pipe(tap((data) => {
        setTimeout(() => {
          console.log(data);
          this.ref.detectChanges();
        }, 0);

      }));
      // console.log(this.res$);
      // this.res$.subscribe(data => {
      //   console.log(data);
      //   this.res = data;
      //
      // });
    }


  }

}

import {Component, Input, OnInit} from '@angular/core';
import {CmsService} from '../../services/cms.service';
import {Browser} from 'leaflet';
import {tap} from 'rxjs/operators';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss']
})
export class DocumentComponent implements OnInit {

  @Input() documentResourceId;
  @Input() selectedCategory;

  document$;

  constructor(private cmsService: CmsService) { }

  ngOnInit() {
    this.document$ = this.cmsService.getDocumentById(this.documentResourceId);
  }

}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .single-page .big-image {
  height: 45vh;
  background-size: cover;
  background-position: bottom;
  background-attachment: fixed;
  background-repeat: no-repeat;
}
::ng-deep .single-page li {
  margin-top: 1em;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/single-page/single-page.component.scss"],"names":[],"mappings":"AAGI;EACE,YAAA;EACA,sBAAA;EACA,2BAAA;EACA,4BAAA;EACA,4BAAA;AAFN;AAII;EACE,eAAA;AAFN","sourcesContent":["@import \"../../../_global-variables\";\n::ng-deep {\n  .single-page {\n    .big-image {\n      height: 45vh;\n      background-size: cover;\n      background-position: bottom;\n      background-attachment: fixed;\n      background-repeat: no-repeat;\n    }\n    li {\n      margin-top: 1em;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

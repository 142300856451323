import { Injectable } from '@angular/core';
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private router:Router) { }

  public searchingString:string = ''
  public itemList
  public itemListFiltered
  public searched = false

  goToSearchPage(){
    this.router.navigate(['/search-result'])
  }

  searchResources() {
    console.log(this.searchingString)
    if (this.searchingString.length > 2) {
      this.searched = true
      this.itemListFiltered = this.itemList
      const lowerValue = this.searchingString.toLowerCase();
      this.itemListFiltered = this.itemList.filter(function(item) {
        if (item.name_en && item.name_en.toLowerCase().indexOf(lowerValue) !== -1) return item.name_en.toLowerCase().indexOf(lowerValue)
        if (item.name_ar && item.name_ar.toLowerCase().indexOf(lowerValue) !== -1) return item.name_ar.toLowerCase().indexOf(lowerValue)
      }

      );
    } else {
      /*console.log('metti piu caratteri')*/
    }
  }


}

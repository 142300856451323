// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-container {
  height: calc(100vh - 114px);
  padding-top: 15vh;
}

.login-form-1 {
  padding: 5%;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 9px 26px 0 rgba(0, 0, 0, 0.19);
}

.login-form-1 h3 {
  text-align: center;
  color: #333;
}

.login-container form {
  padding: 10%;
}

.btnSubmit {
  width: 50%;
  border-radius: 1rem;
  padding: 1.5%;
  border: none;
  cursor: pointer;
}

.login-form-1 .btnSubmit {
  font-weight: 600;
  color: #fff;
  background-color: #0062cc;
}

.login-form-1 .ForgetPwd {
  color: #0062cc;
  font-weight: 600;
  text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/app/components/login/login.component.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;EACA,iBAAA;AACF;;AACA;EACE,WAAA;EACA,4EAAA;AAEF;;AAAA;EACE,kBAAA;EACA,WAAA;AAGF;;AAAA;EACE,YAAA;AAGF;;AADA;EAEE,UAAA;EACA,mBAAA;EACA,aAAA;EACA,YAAA;EACA,eAAA;AAGF;;AADA;EACE,gBAAA;EACA,WAAA;EACA,yBAAA;AAIF;;AADA;EACE,cAAA;EACA,gBAAA;EACA,qBAAA;AAIF","sourcesContent":[".login-container{\n  height: calc(100vh - 114px);\n  padding-top: 15vh;\n}\n.login-form-1{\n  padding: 5%;\n  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 9px 26px 0 rgba(0, 0, 0, 0.19);\n}\n.login-form-1 h3{\n  text-align: center;\n  color: #333;\n}\n\n.login-container form{\n  padding: 10%;\n}\n.btnSubmit\n{\n  width: 50%;\n  border-radius: 1rem;\n  padding: 1.5%;\n  border: none;\n  cursor: pointer;\n}\n.login-form-1 .btnSubmit{\n  font-weight: 600;\n  color: #fff;\n  background-color: #0062cc;\n}\n\n.login-form-1 .ForgetPwd{\n  color: #0062cc;\n  font-weight: 600;\n  text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

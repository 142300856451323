import { Injectable } from '@angular/core';
import {CmsService} from "./cms.service";
import {Router} from "@angular/router";
import {forEach} from "lodash";


export interface Me {
  id: number
  groups: any[]
  password: string
  last_login: any
  is_superuser: boolean
  username: string
  first_name: string
  last_name: string
  email: string
  is_staff: boolean
  is_active: boolean
  date_joined: string
  user_permissions: any[]
}

@Injectable({
  providedIn: 'root'
})


export class LoginService {
  constructor(private cmsService: CmsService, private router: Router) {
  }

  public token: string

  public isLoggedIn: boolean = false

  public me: Me | null = null

  public roles: string[] = []

  public loginError: boolean = false


  login(user, pass, reload?){
    this.cmsService.getToken(user, pass).subscribe(
      data => {
        this.token = data.token;
        this.saveToken(data.token);
        this.getMe(data.token, reload);
        this.loginError = false;
      }, error => {
        console.log('error', error);
        this.loginError = true;
      }
    )
  }

  getMe(token, reload?){
    this.cmsService.getMe(token).subscribe(
      data => {
        console.log('me',data);
        this.me = data;
        this.addRole(data);
        this.isLoggedIn = true;
        if(reload){
          window.location.reload();
        }
      }, error => {
        console.log('error', error);
        //remove token from local storage
        window.localStorage.removeItem('token');
        this.router.navigate(['/login']);
      }
    )
  }

  logout() {
    this.me = null;
    this.token = null;
    this.isLoggedIn = false;
    this.roles = [];
    window.localStorage.removeItem('token');
    //reload page
    window.location.reload();
  }

  saveToken(token){
    window.localStorage.setItem('token', token);
  }

  getToken(){
    return window.localStorage.getItem('token');
  }

  addRole(me){
     forEach(me.groups, (group) => {
       this.roles.push(group.name)
     })
    console.log('roles', this.roles)
  }

}

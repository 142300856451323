// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.embed-container {
  position: relative;
  padding-bottom: 63%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  height: auto;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 13%;
  width: 100%;
  height: 75%;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/video-tutorial/video-tutorial.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,mBAAA;EACA,SAAA;EACA,gBAAA;EACA,eAAA;EACA,YAAA;AACF;;AAEA;;;EAGE,kBAAA;EACA,QAAA;EACA,WAAA;EACA,WAAA;AACF","sourcesContent":[".embed-container {\n  position: relative;\n  padding-bottom: 63%;\n  height: 0;\n  overflow: hidden;\n  max-width: 100%;\n  height: auto;\n}\n\n.embed-container iframe,\n.embed-container object,\n.embed-container embed {\n  position: absolute;\n  top: 13%;\n  width: 100%;\n  height: 75%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

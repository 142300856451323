import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@environment';
import {combineLatest, Observable} from 'rxjs';
import {combineAll, map, switchMap, tap} from 'rxjs/operators';
import {CmsService} from './cms.service';
import {Chart} from "../interfaces/chart";


@Injectable({
  providedIn: 'root'
})
export class SerieService {

  constructor(private http: HttpClient, private cmsService: CmsService) { }

  environment = environment;

  baseUrl = this.environment.apiUrl + 'series/';


  getSerie(serie: string) {
    return this.http.get<any>(this.baseUrl + serie + '/');
  }

  getSeries(series: string[]) {
    const series$ = series.map(serie => {
      return this.http.get(this.baseUrl +  serie + '/');
    });
    return combineLatest(series$);
  }

  getSerieById(id: string[]) {
    return this.http.get<any>(this.baseUrl + 'charts/?dataid=' + id).pipe(
      map(data => {

        return data;

      })
    );
  }

  exportData(resource) {
    return this.cmsService.getFullResourceByTypeAndId('graph', resource.id).pipe(
        // @ts-ignore
        switchMap(fullResource => this.getSerie(fullResource.configuration.serie)),
        map(data => {

          let csvContent = 'data:text/csv;charset=utf-8,';
          csvContent += data.labels.join(',') + '\n';

          if ( Object.keys(data.values).length > 0 && !Array.isArray(data.values)) {

            Object.keys(data.values).map(val => {
              csvContent += data.values[val].join(',') + '\n';
            });

          } else {
            csvContent += data.values.join(',');
          }
          const encodedUri = encodeURI(csvContent);
          window.open(encodedUri);
        })
    );
  }
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CmsService} from '../../services/cms.service';
import {Category} from '../../interfaces/category';
import {map, tap} from 'rxjs/operators';
import {forkJoin} from 'rxjs';
import {Layer, WebgisMap} from '../../interfaces/webgis';
import {TranslateService} from '@ngx-translate/core';
import {LoginService} from "../../services/login.service";

@Component({
  selector: 'app-layer-tree',
  templateUrl: './layer-tree.component.html',
  styleUrls: ['./layer-tree.component.scss']
})
export class LayerTreeComponent implements OnInit {

  @Input() uid;
  @Output() layerSelected = new EventEmitter();

  categories$ = this.cmsService.getCategories(this.loginService.token).pipe(tap(data => this.categories = data));

  layers$ = this.cmsService.getLayers().pipe(tap(data => this.layers = data));

  selectedLayer: Layer;

  currentLang;

  dropdownSettings = {
    singleSelection: true,
    text: 'Sélectionnez Layer',
    selectAllText: 'Tout Sélectionner',
    unSelectAllText: 'Tout Déselectionner',
    searchPlaceholderText: 'Chercher',
    enableSearchFilter: true,
    classes: 'myclass custom-class'
  };


  forkJoin$ = forkJoin(this.layers$, this.categories$).pipe(map(
      (result) => {
        result[0].map((layer) => {
          if ( layer.topics.length > 0) {
            layer.topics.map((topic) => {
              result[1].sort((a, b) => a.order - b.order); // order category by id
              result[1].map((category) => {
                category.indicators.map((indicator) => {
                  if (indicator.layers && indicator.id === topic) {
                    indicator.layers.push(layer);
                  } else {
                    if (indicator.id === topic) {
                      indicator.layers = [layer];
                    }
                  }
                });
              });
            });
          }
        });
        return result;
      }
  ));

  categories: Category[];
  layers: Layer[];

  constructor(private cmsService: CmsService, public loginService: LoginService, private translateService: TranslateService) {
    console.log('init layer tree');
  }


  onItemSelect(layer) {
    console.log(layer);
  }

  loadLayer(layer, indicator, category) {
    this.layerSelected.emit({layer, indicator, category});
  }


  hideShow(id) {
    if (document.getElementById(id).classList.contains('show')) {
      document.getElementById(id).classList.remove('show');
      document.getElementById(id).classList.add('hide');
    } else if (document.getElementById(id).classList.contains('hide')) {
      document.getElementById(id).classList.remove('hide');
      document.getElementById(id).classList.add('show');
    }
  }

  ngOnInit() {
    this.currentLang = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe(lang => {
      this.currentLang = lang.lang;
    });
  }

}

import {Component, Input, OnInit, SimpleChanges} from '@angular/core';
import {CmsService} from '../../services/cms.service';
import {TranslateService} from '@ngx-translate/core';
import {LoginService} from "../../services/login.service";

@Component({
  selector: 'app-resources-last',
  templateUrl: './resources-last.component.html',
  styleUrls: ['./resources-last.component.scss']
})
export class ResourcesLastComponent implements OnInit {


  @Input() howManyResources ;

  resources;
  filteredResources;

  itemPerPage = 4;

  pages;
  pageSelected = 0;
  indexOfViewing = 0;

  currentLang = 'en';

  constructor(private cmsService: CmsService, private translateService: TranslateService, private loginService: LoginService) {
      this.currentLang = this.translateService.currentLang;

  }

  setFilteredResource(event){
  }

  ngOnInit() {
    this.translateService.onLangChange.subscribe(lang => {
      this.currentLang = lang.lang;
    });
    this.cmsService.getLastResources(this.loginService.token).subscribe(data => {
      this.resources = data;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    // changes.prop contains the old and the new value...

  }




}

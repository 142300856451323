import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-share-item',
  templateUrl: './share-item.component.html',
  styleUrls: ['./share-item.component.scss']
})
export class ShareItemComponent implements OnInit {

  @Input() title: string = 'Item'
  @Input() url?: string = ''

  constructor() { }


  ngOnInit() {
    if(!this.url) {
      this.url = window.location.href
    } else {
      let rootUrl = window.location.href.split('#')[0]
      this.url = rootUrl + this.url
    }
  }

  shareOnFacebook() {
    window.open('https://www.facebook.com/sharer/sharer.php?u='+this.url, '_blank', 'width=600,height=400');
  }
  shareOnTwitter() {
    window.open('https://twitter.com/intent/tweet?text='+this.title+'&url='+this.url, '_blank', 'width=600,height=400');
  }
  shareOnInstagram() {
    window.open('https://www.instagram.com/?url='+this.url, '_blank');
  }
  shareOnLinkedin() {
    window.open('https://www.linkedin.com/shareArticle?mini=true&url='+this.url+'&title='+this.title+'&summary=&source=', '_blank', 'width=600,height=400');
  }

}

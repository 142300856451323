import {Layer, LayersGroup, LayerOnMap} from '../interfaces/webgis';
import * as L from 'leaflet';
import {HttpClient} from '@angular/common/http';
import {ApiService} from '../services/api.service';
import {ConfigService} from '../services/config.service';
import {Observable, Subject, Subscription} from 'rxjs';





export class LayerManager {

    layerConfig: Layer;
    leafletMap: L.Map;
    leafletLayer: L.Layer;
    layerOnMap: LayerOnMap;
    layerGroup: LayersGroup;
    apiService: ApiService;
    configService: ConfigService;
    queueSubscription: Subscription = new Subscription();
    queueSubject: Subject<any> | Observable<any>;

    constructor( layerOnMap: LayerOnMap, leafletMap: L.Map, apiService: ApiService, configService: ConfigService) {

        this.layerOnMap = layerOnMap;
        this.layerConfig = layerOnMap.layer;
        this.leafletMap = leafletMap;
        this.apiService = apiService;
        this.configService = configService;
        this.queueSubject = this.configService.getQueue();
        this.load();
    }

    name(lang?) {
   /*   console.log('lang per name', lang)*/
        if (!lang) {
          return this.layerConfig.name
        } else {
          return this.layerConfig['name_'+lang];
        }

    }

    description() {
        return this.layerConfig.description;
    }

    layer_id() {
        return this.layerConfig.layer_id;
    }

    type() {
        return this.layerConfig.type;
    }

    data_source() {
        return this.layerConfig.data_source;
    }

    getLafletLayer() {
        return this.leafletLayer;
    }

    remove() {
        try {
            this.leafletMap.removeLayer(this.leafletLayer);
        } catch (e) {
            console.log(e);
        }
    }

    load() {}
}

export default LayerManager;

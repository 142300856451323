import { Component, OnInit } from '@angular/core';
import {environment} from '@environment';
import {TranslateService} from '@ngx-translate/core';
import {CmsService} from "../../services/cms.service";
import {LoginService} from "../../services/login.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  constructor(private cmsService: CmsService,
              private router: Router,
              public loginService: LoginService) {
  }

  username:string=''
  password:string =''

  error=false

  doLogin(){
    this.loginService.login(this.username, this.password, true);
  }

  ngOnInit() {
    //if token in local storage, redirect to home
    if (this.loginService.token) {
      this.router.navigate(['/']);
    }

  }

}

import { Component, OnInit } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-chart-dialog',
  templateUrl: './chart-dialog.component.html',
  styleUrls: ['./chart-dialog.component.scss']
})
export class ChartDialogComponent implements OnInit {

  resource;
  currentLang;
  constructor(public activeModal: NgbActiveModal,  private translateService: TranslateService) { }

  ngOnInit() {
    this.currentLang = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe(lang => {
      this.currentLang = lang.lang;
    });
  }

}

    import {LayerManager} from './layer.class';
    import * as L from 'leaflet';
    import {LayerOnMap} from '../interfaces/webgis';
    import {ApiService} from '../services/api.service';
    import {map, timeout} from 'rxjs/operators';
    import WmsClass from './wms.class';

    import {ConfigService} from '../services/config.service';
    import * as moment from 'moment';

    export class WmstClass extends WmsClass {


        constructor( layerOnMap: LayerOnMap, leafletMap: L.Map, apiService: ApiService, configService: ConfigService) {
            super( layerOnMap, leafletMap, apiService, configService);

          if (this.queueSubject) {
            this.queueSubscription.add(
              this.queueSubject.subscribe(message => {
                switch (message.text) {
                  case 'clock.dateTo.updated':
                    console.log(message.text);
                    this.load();
                    break;
                  default:
                    console.log('Unknown message ' + message.text);
                }
              })
            );
          }

        }

        load() {

            //console.log('Load Layer WMST' +  this.layerConfig.date_last);

            let opacity = this.layerOnMap.is_visible ? 1 : 0;

            if (this.leafletLayer) {
                opacity = this.getOpacity();
                this.leafletMap.removeLayer(this.leafletLayer);
            }

            let date

          //console.log('dateTO', this.configService.getDateTo())

            if (this.layerConfig.date_last && !this.configService.getDateTo()) {
              //console.log('vado di data salvata a db')
              date = this.layerConfig.date_last
            } else {
              //console.log('vado di data selezionata')
              date = moment(this.configService.getDateTo()).format('YYYY-MM-DD');
            }


            this.leafletLayer = L.tileLayer.wms(this.data_source().url,
                {
                    layers: this.layerConfig.layer_id,
                    opacity,
                    transparent: true,
                    format: 'image/png',
                    // @ts-ignore
                    // time: '2020-03-01'
                    time: date,
                    // crs: L.CRS.EPSG4326 forzato per vedere layer proiettati sbagliati

                }).addTo(this.leafletMap);/*.
                bringToFront()*/
        }


        remove() {
          console.log('remove layer')
            try {
                this.leafletMap.removeLayer(this.leafletLayer);
                // unsubscribe
              console.log(this.queueSubscription)
              this.queueSubscription.unsubscribe();
            } catch (e) {
                console.log(e);
            }
        }
    }

    export default WmstClass;

import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CmsService} from '../../services/cms.service';
import {TranslateService} from '@ngx-translate/core';
import {tap} from 'rxjs/operators';

@Component({
  selector: 'app-single-page',
  templateUrl: './single-page.component.html',
  styleUrls: ['./single-page.component.scss']
})
export class SinglePageComponent implements OnInit {

  public title;
  public text;
  public img;
  page$;
  currentLang = 'en';

  constructor( private activatedRoute: ActivatedRoute, private cmsService: CmsService, private translateService: TranslateService) {

  }

ngOnInit() {
    this.activatedRoute.params.subscribe(params => {

        this.translateService.onLangChange.subscribe(lang => {
            this.currentLang = lang.lang;
        });
        this.page$ = this.cmsService.getHtmlPagesById(params.pageId);
    });

    }

}

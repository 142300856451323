// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pillow {
  border-radius: 30px;
  color: #fff;
}
.pillow:hover {
  text-decoration: none;
  transform: scale(1.1);
  transition: all 0.2s ease;
}`, "",{"version":3,"sources":["webpack://./src/app/components/document/document.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,WAAA;AACF;AAAE;EACE,qBAAA;EACA,qBAAA;EACA,yBAAA;AAEJ","sourcesContent":[".pillow {\n  border-radius:30px;\n  color: #fff;\n  &:hover {\n    text-decoration: none;\n    transform: scale(1.1);\n    transition: all 0.2s ease;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

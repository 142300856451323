// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.doc-details-text div {
  padding-top: 0.6em;
}

.doc-details-img .featured-img {
  height: 180px;
  background-size: cover;
  width: 100%;
}
.doc-details-img img {
  max-height: 180px;
}

.topic::after {
  content: ",";
}

.topic:last-child::after {
  content: "";
}

.cat-icon {
  font-size: 4em;
}

::ng-deep .inverted-layout .doc-details {
  direction: rtl;
  text-align: right;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/doc-details/doc-details.component.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;AAAJ;;AAKA;EACE,aAAA;EACA,sBAAA;EACA,WAAA;AAFF;AAIE;EACE,iBAAA;AAFJ;;AAMA;EACE,YAAA;AAHF;;AAKA;EACE,WAAA;AAFF;;AAIA;EACE,cAAA;AADF;;AAMI;EACE,cAAA;EACA,iBAAA;AAHN","sourcesContent":[".doc-details-text {\n  div {\n    padding-top: 0.6em;\n  }\n}\n\n.doc-details-img {\n.featured-img {\n  height: 180px;\n  background-size: cover;\n  width: 100%;\n  }\n  img {\n    max-height: 180px;\n  }\n}\n\n.topic::after{\n  content: ',';\n}\n.topic:last-child::after{\n  content: '';\n}\n.cat-icon {\n  font-size: 4em;\n}\n\n::ng-deep {\n  .inverted-layout {\n    .doc-details {\n      direction: rtl;\n      text-align: right;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

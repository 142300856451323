import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {forkJoin, Observable, Subject} from 'rxjs';
import domtoimage from 'dom-to-image';
import {jsPDF} from 'jspdf';
import {LoginService} from "./login.service";



@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  dateTo;
  dateFrom;

  private generalQueue = new Subject<any>();

  constructor(private http: HttpClient, public loginService: LoginService) {}


  getQueue(): Observable<any> {
    return this.generalQueue.asObservable();
  }

  setDateTo(date) {
    this.dateTo = date;
    this.generalQueue.next({ text: 'clock.dateTo.updated' });
  }

  getDateTo() {
    return this.dateTo;
  }


  canPrintChart() {
     return this.loginService.isLoggedIn;
  }

  canPrintDashboard() {
    if ( this.loginService.roles.length > 0 ) {
      return (this.loginService.roles.indexOf('reporter') > -1);
    } else {
      return false;
    }

  }

  canDowloadCsv() {
    return this.loginService.isLoggedIn;
  }

  print(id, name, bgColor?) {
    domtoimage.toJpeg(document.getElementById(id), { quality: 0.95, bgcolor: (bgColor) ? bgColor : '#fff' })
        .then((dataUrl) => {
          const link = document.createElement('a');
          link.download = name + '.jpeg';
          link.href = dataUrl;
          link.click();
        });
  }

  downloadDataInCsv(csvContent, name?) {
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', (name) ? name + '.csv' : 'data.csv');
    document.body.appendChild(link); // Required for FF

    link.click();
  }

  getPdfFromApps(url) {

    const obj =  {
      layout: {
        format: 'A4',
        margin: {
          top: '0px',
          bottom: '40px'
        },
        // tslint:disable-next-line:max-line-length
        footerTemplate: '<div style=\'left:90%; right: 40px; margin-top: 5px\'><span style=\'background-color:#e99f36; font-size: 12px; font-weight: 700;padding: 2px 5px;\'><span class=\'pageNumber\'></span>/<span class=\'totalPages\'></span></span></div>',
        headerTemplate: '<span></span>',
        displayHeaderFooter: true
      }
    };
    return this.http.post('https://apps.cimafoundation.org/nodeprintservicepdf/?url=' + url, obj);

  }

  donwloadExportedPdf(resources, lang) {

    const doc = new jsPDF();
    const arrayOfFrom = [];

    doc.setFont('times');
    // doc.setFontType("italic");

    //  console.log(resources);



    resources.map((resource) => {
      switch (resource.resource_type) {
        case 'graph':

          arrayOfFrom.push(new Observable(subscriber => {

            domtoimage.toJpeg(document.getElementById('content-' + resource.resource_type + resource.id), {
              quality: 1,
              bgcolor: '#fff'
            }).then(data => {
              subscriber.next({type: 'graph', resource, image: data});
              subscriber.complete();
            });
          }));
          break;
        case 'document':
          arrayOfFrom.push(new Observable(subscriber => {
            subscriber.next({type: 'document', resource});
            subscriber.complete();
            // domtoimage.toJpeg(document.getElementById('content-' + resource.resource_type + resource.id), {
            //   quality: 1,
            //   bgcolor: '#fff'
            // }).then(data => {
            //   subscriber.next({type: 'docu', resource, image: data});
            //   subscriber.complete();
            // });
          }));
          break;
        case 'link':
          arrayOfFrom.push(new Observable(subscriber => {
            subscriber.next({type: 'link', resource});
            subscriber.complete();
            // domtoimage.toJpeg(document.getElementById('content-' + resource.resource_type + resource.id), {
            //   quality: 1,
            //   bgcolor: '#fff'
            // }).then(data => {
            //   subscriber.next({type: 'docu', resource, image: data});
            //   subscriber.complete();
            // });
          }));
          break;
        case 'webgis':
          arrayOfFrom.push(new Observable(subscriber => {

            domtoimage.toJpeg(document.getElementById('content-' + resource.id), {
              quality: 1,
            }).then(data => {
              subscriber.next({type: 'webgis', resource, image: data});
              subscriber.complete();
            });

            // subscriber.next({type: 'webgis', resource});
            // subscriber.complete();
          }));
          break;
        case 'story':
          arrayOfFrom.push(new Observable(subscriber => {

            domtoimage.toJpeg(document.getElementById('content-' + resource.id), {
              quality: 1,
            }).then(data => {
              subscriber.next({type: 'story', resource,  image: data});
              subscriber.complete();
            });
          }));
          break;
      }
    });

   // console.log(arrayOfFrom);

    const fork = forkJoin(arrayOfFrom);

    // console.log(fork);

    fork.subscribe((data) => {
      // console.log(data);

      // @ts-ignore
      doc.addImage(document.getElementById('logo-iraq'), 'JPEG', 20, 40, 180, 130);

      data.map((obsResult) => {
        console.log(obsResult.type);

        switch (obsResult.type) {
          case 'document':
            doc.addPage();

            doc.setFontSize(10);
            doc.setTextColor('black');
            doc.text('Resource Type : Document', 10 , 20, { maxWidth : 180});
            doc.setFontSize(20);
            doc.setTextColor('red');
            doc.text(obsResult.resource['name_' + lang], 10 , 40, { maxWidth : 180});
            doc.setFontSize(10);
            doc.setTextColor('black');
            doc.textWithLink(obsResult.resource['description_' + lang], 10, 60, {url: obsResult.resource.content, maxWidth : 180});
            break;
          case 'graph':
            doc.addPage();

            doc.setFontSize(10);
            doc.setTextColor('black');
            doc.text('Resource Type : Chart', 10 , 20, { maxWidth : 180});
            doc.setFontSize(20);
            doc.setTextColor('red');
            doc.text(obsResult.resource['name_' + lang], 10 , 40);
            doc.setFontSize(10);
            doc.setTextColor('black');
            doc.text(obsResult.resource['description_' + lang], 10 , 50);
            doc.addImage(obsResult.image, 'JPEG', 15, 60, 180, 160);
            break;
          case 'story':
            doc.addPage();
            doc.text('Resource Type : Story', 10 , 20, { maxWidth : 180});
            // doc.setFontSize(20);
            // doc.setTextColor('red');
            // doc.text(obsResult.resource['name_' + lang], 10 , 40);
            // doc.html(obsResult.resource['description_' + lang].replace(/<[^>]*>?/gm, ''));
            doc.setFontSize(10);
            doc.setTextColor('black');
            // doc.text(obsResult.resource['description_' + lang].replace(/<[^>]*>?/gm, ''), 10, 60, {maxWidth : 180});

            // debugger
            doc.text(obsResult.resource['title_' + lang], 10 , 10);
            // doc.text(obsResult.resource['description_' + lang], 10 , 10);
            doc.addImage(obsResult.image, 'JPEG', 15, 40, 180, 160);
            break;
          case 'link':
            // debugger
            doc.addPage();
            doc.setFontSize(20);
            doc.setTextColor('red');
            // console.log(obsResult.resource['name_' + lang]);
            // console.log(obsResult.resource);
            doc.text(obsResult.resource['name_' + lang], 20 , 20);
            doc.setFontSize(10);
            doc.setTextColor('black');
            // doc.text(obsResult.resource['description_' + lang], 20, 40, {maxWidth : 180});

            if (obsResult.resource.resource_type === 'link') {
              doc.textWithLink(obsResult.resource['name_' + lang], 20, 40, {url: obsResult.resource.url});
            } else if (obsResult.resource.resource_type === 'video') {
              doc.textWithLink(obsResult.resource['name_' + lang], 20, 40, {url: 'https://www.youtube.com/watch?v=' + obsResult.resource.url});
            }

            // doc.text(obsResult.resource['title_' + lang], 10 , 10);
            // doc.text(obsResult.resource['description_' + lang], 10 , 10);

            break;
          default:
            doc.addPage();

            doc.setFontSize(10);
            doc.setTextColor('black');
            doc.text('Resource Type : Default', 10 , 20, { maxWidth : 180});
            doc.setFontSize(20);
            doc.setTextColor('red');
            doc.text(obsResult.resource['name_' + lang], 10 , 40);
            doc.setFontSize(10);
            doc.setTextColor('black');
            doc.text(obsResult.resource['description_' + lang], 10 , 50);
            doc.addImage(obsResult.image, 'JPEG', 15, 60, 180, 160);
            break;


        }
      });

      doc.save('export.pdf');
    });
  }

}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map-dashboard {
  height: 300px;
  width: 400px;
}

.map-fullpage {
  height: calc(100vh - 80px - 40px - 34px);
  width: 100%;
}

.gis-map {
  height: calc(100vh - 80px - 40px - 34px);
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/components/webgis/webgis.component.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,YAAA;AADF;;AAIA;EACE,wCAAA;EACA,WAAA;AADF;;AAIA;EACE,wCAAA;EACA,WAAA;AADF","sourcesContent":["@import \"../../../_global-variables\";\n\n.map-dashboard{\n  height: 300px;\n  width: 400px;\n}\n\n.map-fullpage{\n  height: calc(100vh - #{$h-header} - #{$h-toolbar} - #{$h-main-footer});\n  width: 100%;\n}\n\n.gis-map {\n  height: calc(100vh - #{$h-header} - #{$h-toolbar} - #{$h-main-footer});\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

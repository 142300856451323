import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {CmsService} from '../../services/cms.service';
import {v4 as uuidv4} from 'uuid';
import * as moment from 'moment';

import { interval } from 'rxjs';


import 'leaflet';


import * as L from 'leaflet';

import 'leaflet.sync';


import {tap, timeout} from 'rxjs/operators';
import {MapComponent} from '../../components/map/map.component';
/*
import {tryCatch} from 'rxjs/internal-compatibility';
*/
import {ConfigService} from '../../services/config.service';


@Component({
    selector: 'app-gis-container',
    templateUrl: './gis-container.component.html',
    styleUrls: ['./gis-container.component.scss']
})
export class GisContainerComponent implements OnInit {
    lockPan = false;
    splitScreen = false;
    webgisId;

    uid;
    webgisConfiguration$;
    webgisConfiguration;

    minDate = new Date(2000, 0, 0, 0, 0);
    maxDate = new Date();
    monthsNumber ;
    monthsSelected;
    interval;
    interval$;
    isMoving = false;

    dateSelected = new Date();

    @ViewChild('main', {static: false}) mainMapComponent: MapComponent;

    @ViewChild('secondary', {static: false}) secondaryMapComponent: MapComponent;

    mainMapObj: any;
    secondaryMapObj: any;

    sliderMonthsSelected(value) {
        this.monthsSelected = parseInt(value, 10 );
        if ( this.monthsSelected > this.monthsNumber ) {
            this.monthsSelected = this.monthsNumber;
            if (this.isMoving) {
                this.stopSLider();
            }
        }
        if ( this.monthsSelected <= 0) {
            this.monthsSelected = 1;
        }
        moment(this.minDate).add(this.monthsSelected, 'months');
        this.dateSelected = moment(this.minDate).add(this.monthsSelected, 'months').add(1,'day').toDate();
      console.log(this.dateSelected)
        this.onDateSelected({value: this.dateSelected});
    }

    playSLider() {
        this.interval$ = interval(3000);
        this.isMoving = true;
        this.interval = this.interval$.subscribe((value) => {

            this.sliderMonthsSelected(this.monthsSelected + 1);

        });
    }

    stopSLider() {
        delete this.interval$;
        this.interval.unsubscribe();
        this.isMoving = false;
    }

    lockerSwitch() {
        this.lockPan = !this.lockPan;
        if (this.lockPan && this.splitScreen) {
            this.mainMapObj = this.mainMapComponent.getMapObj();
            try {
                this.secondaryMapObj = this.secondaryMapComponent.getMapObj();
                this.mainMapObj.sync(this.secondaryMapObj);
                this.secondaryMapObj.sync(this.mainMapObj);
            } catch (e) {
                console.log(e);
            }
        } else {
            try {
                // @ts-ignore
                this.mainMapComponent.getMapObj().unsync(this.secondaryMapComponent.getMapObj());
                // @ts-ignore
                this.secondaryMapComponent.getMapObj().unsync(this.mainMapComponent.getMapObj());
            } catch (e) {
                console.log(e);
            }
        }
    }

    splitterSwitch() {
        this.splitScreen = !this.splitScreen;
        setTimeout(() => { // TODO poco elegante il timeout
            this.mainMapComponent.map.invalidateSize();
            //this.mainMapComponent.setBBOX(); // ripristina zoom iniziale serve?!
        }, 500);

    }

  public filterFirstDay = (d: Date): boolean => {
    const day = d.getDate()
    // filtra il calendario solo primo giorno di ogni mese
    return day === 1;
  }

    setBBOX() {
        if (this.splitScreen) {
            this.mainMapComponent.setBBOX();
            this.secondaryMapComponent.setBBOX();
        } else {
            this.mainMapComponent.setBBOX();
        }
    }


    constructor(private activatedRoute: ActivatedRoute, private cmsService: CmsService, private configService: ConfigService) {
    }

    ngOnInit() {

        this.monthsNumber = moment(this.maxDate).diff(this.minDate, 'months');
        this.monthsSelected = this.monthsNumber;

        this.activatedRoute.params.subscribe(params => {
            this.webgisId = params.webgisId;

            this.webgisConfiguration$ = this.cmsService.getWebgisMap(this.webgisId).pipe(
                tap(data => {
                    this.webgisConfiguration = data;
                })
            );

        });
    }

    onDateSelected(time) {
        this.configService.setDateTo(time.value);
    }

    // tslint:disable-next-line:use-lifecycle-interface
    ngAfterViewInit() {
        // @ts-ignore
    }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-report-wizard',
  templateUrl: './report-wizard.component.html',
  styleUrls: ['./report-wizard.component.scss']
})
export class ReportWizardComponent implements OnInit {

  constructor() { }

  wizardStep = 1;

  stepper(stepNumber) {
    this.wizardStep = this.wizardStep + stepNumber;
  }

  ngOnInit() {
  }

}

import { Component, OnInit } from '@angular/core';
import {SearchService} from "../../services/search.service";
import {CmsService} from "../../services/cms.service";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss']
})
export class SearchResultComponent implements OnInit {

  constructor(public searchService: SearchService,
              private cmsService: CmsService,
              private router: Router,
              private translateService: TranslateService) { }

  loading = false
  currentLang

  ngOnInit() {
    this.loading = true
    this.cmsService.getAllResource().subscribe(res => {
      this.searchService.itemList = res;
      //this.searchService.itemListFiltered = res;
      //console.log('test ',this.searchService.itemList)
      this.searchService.searchResources()
      this.loading = false
    });
    this.cmsService.getAllTopics().subscribe(res => this.topics = res);

    this.currentLang = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe(lang => {
      this.currentLang = lang.lang;
    });

  }

  topics

  goTo(item) {
    let catI = 1;
    if (item.topics.length > 0) {
      this.topics.map((topic) => {
        if (item.topics[0] === topic.name) { catI = topic.category; }
      });
    }
    this.router.navigate(['doc-details/' + item.resource_type + '/' + catI.toString() + '/' + item.id]);
  }

}

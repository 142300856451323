// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper {
  position: relative;
  width: 100%;
  min-height: 25vh;
  height: 100%;
  object-fit: fill;
  display: flex;
  overflow: hidden;
}

canvas {
  background: #fff;
}

h1 {
  font-family: Roboto;
  color: #fff;
  font-weight: 200;
  text-align: center;
  display: block;
  text-decoration: none;
}

canvas {
  background-color: white;
}`, "",{"version":3,"sources":["webpack://./src/app/components/charts/stackedbar-chart/stackedbar-chart.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,gBAAA;EACA,YAAA;EACA,gBAAA;EACA,aAAA;EACA,gBAAA;AACF;;AAIA;EACE,gBAAA;AADF;;AAMA;EACE,mBAAA;EACA,WAAA;EAEA,gBAAA;EACA,kBAAA;EACA,cAAA;EACA,qBAAA;AAJF;;AAMA;EACE,uBAAA;AAHF","sourcesContent":[".wrapper{\n  position: relative;\n  width:100%;\n  min-height: 25vh;\n  height: 100%;\n  object-fit: fill;\n  display:flex;\n  overflow:hidden;\n\n}\n\n\ncanvas{\n  background:#fff;\n  //height:400px;\n}\n\n\nh1 {\n  font-family: Roboto;\n  color: #fff;\n  //margin-top:50px;\n  font-weight:200;\n  text-align: center;\n  display: block;\n  text-decoration: none;\n}\ncanvas {\n  background-color: white;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

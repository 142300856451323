import {AfterViewInit, Component, Input, OnInit, SimpleChanges} from '@angular/core';
import * as ChartJs from 'chart.js';
import { v4 as uuidv4 } from 'uuid';
import {Chart} from '../../../interfaces/chart';
import {SerieService} from '../../../services/serie.service';
import * as _ from 'lodash';
import validate = WebAssembly.validate;
import {ConfigService} from '../../../services/config.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent implements OnInit {

  @Input() chartObj: Chart;

  uid;
  canvas;
  ctx;
  Chart;
  series;
  parsedData;
  currentLang;

  ngOnInit() {
   // console.log('data LINE', this.chartObj)
  }

  loadingChart = false

  constructor(private serieService: SerieService, private configService: ConfigService, private translateService: TranslateService) {
    this.uid = uuidv4();
  }


  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit() {
    this.currentLang = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe(lang => {
      this.currentLang = lang.lang;
    });
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnChanges(change: SimpleChanges) {
    if (change.chartObj) {
      this.loadSeries();

    }

  }
  canPrint() {
    return this.configService.canPrintChart();
  }
  canExportData() {
    return this.configService.canDowloadCsv();
  }
  exportData() {

    let csvContent = 'data:text/csv;charset=utf-8\n,';
    csvContent += this.parsedData.labels.join(',') + '\n';

    // csvContent += this.parsedData.datasets.map(row => (row.label) ? row.label : '' + row.data.join(',') + '\n');
    this.parsedData.datasets.map((data) => {
      csvContent += data.data.join(',') + '\n';
    });
    this.configService.downloadDataInCsv(csvContent , this.chartObj['name_' + this.currentLang]);
  }

  print(id) {
    this.configService.print(id , this.chartObj.name_en, '#fff' );
  }

  parseData(selectedSeries) {

    let isMultiline;

    if (Array.isArray(selectedSeries.values[Object.keys(selectedSeries.values)[0]])) {
      isMultiline = true;
    }

    let backgroundColor = [];
    if (this.chartObj.configuration.hasOwnProperty('colors')) {
      backgroundColor = this.chartObj.configuration.colors;
    } else {
      selectedSeries.labels.map(serie => {
        backgroundColor.push('#' + (0x1000000 + (Math.random()) * 0xffffff).toString(16).substr(1, 6));
      });
    }


    if (isMultiline) {
      const labels = Object.keys(selectedSeries.values);

      const datasets = [];

      selectedSeries.labels.map((value, i) => {

        // const data = Object.keys(selectedSeries.values).map(val => selectedSeries.values[val][i].toFixed(1));
        const data = Object.keys(selectedSeries.values).map(val => selectedSeries.values[val][i]);

        const dataset = {
          label: value,
          data,
          borderColor: backgroundColor[i],
          fill: false
        };
        datasets.push(dataset);
      });


      this.parsedData = {
        labels,
        datasets
      };
    } else {

      this.parsedData = {
        labels : selectedSeries.labels,
        datasets : [{
          label: this.chartObj.configuration.serie,
          // data: selectedSeries.values.map(d => d.toFixed(1)),
          data: selectedSeries.values,
          borderColor: '#' + (0x1000000 + (Math.random()) * 0xffffff).toString(16).substr(1, 6),
          fill: false
        }]
      };
    }


  }

  loadSeries() {
    this.loadingChart = true
    this.serieService.getSerieById(this.chartObj.configuration.serie).subscribe(
        data => {
          this.series = data[0].data;
          this.loadingChart = false
          //se serie contiene più oggetti
          if (this.series.length>0) {
            this.selectedSeries = this.series[0].name
            this.parseData(this.series[0])
          }
          //altrimenti carico grafico semplice
          else this.parseData(this.series)
          this.loadChart();
        }
    );
  }

  selectedSeries
  changeSerie(newSerieName){
    this.parsedData = []
    let newSerie = this.series.find(x => x.name == newSerieName)
    this.parseData(newSerie)
    this.loadChart();
  }


  loadChart() {

    let displayLegend: boolean = this.parsedData.datasets.length > 1

    if(this.Chart!=null){
      this.Chart.destroy();
    }

    this.canvas = document.getElementById(this.uid);
    this.ctx = this.canvas.getContext('2d');
    this.Chart = new ChartJs(this.ctx, {
      type: 'line',
      data: this.parsedData,
      options: {
        scales: {
          xAxes: [{
            scaleLabel: {
              display: (this.chartObj.configuration.hasOwnProperty('label_x')),
              labelString: (this.chartObj.configuration.hasOwnProperty('label_x')) ? this.chartObj.configuration.label_x : ''
            }
          }],
          yAxes: [{
            scaleLabel: {
              display: (this.chartObj.configuration.hasOwnProperty('label_y')),
              labelString: (this.chartObj.configuration.hasOwnProperty('label_y')) ? this.chartObj.configuration.label_y : ''
            }
          }]
        },
        legend: {
          display: displayLegend
        },
        maintainAspectRatio: false,
        display: true,
        tooltips: {
          enabled: true,
          mode: 'nearest',
          callbacks:  {
            label: (tooltipItems, data) => {
              // console.log(data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index]);
              // console.log(this.chartObj.configuration.hasOwnProperty('um'));
              const um = (this.chartObj.configuration.hasOwnProperty('um')) ? this.chartObj.configuration.um : '';
              return data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index] + ' ' + um;
            }
          }
        }
      }

    });
  }

}

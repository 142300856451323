import { Component, OnInit } from '@angular/core';
import {CmsService} from '../../services/cms.service';
import {TranslateService} from '@ngx-translate/core';
import {FilterSearchPipe} from '../../pipes/filter-search.pipe';
import {Router} from '@angular/router';
import {Resource} from '../../interfaces/resource';
import {withIdentifier} from 'codelyzer/util/astQuery';
import {SearchService} from "../../services/search.service";


@Component({
  selector: 'app-resources-search',
  templateUrl: './resources-search.component.html',
  styleUrls: ['./resources-search.component.scss'],
  providers: [FilterSearchPipe]
})
export class ResourcesSearchComponent implements OnInit {

  itemList: any = [];
  itemListFiltered: any = [];
  selectedItems = [];
  settings = {};
  topics;
  text;
  currentLang = 'en';


  constructor(private cmsService: CmsService,
              private translateService: TranslateService,
              private searchFilterPipe: FilterSearchPipe,
              private router: Router,
              public searchService: SearchService) { }

  ngOnInit() {

    this.translateService.get('pages.search_resources.settings.text').subscribe(x => this.text = x);
    this.currentLang = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe(lang => {
      this.currentLang = lang.lang;
    });

    this.settings = {
      singleSelection: true,
      text: this.text,
      selectAllText: this.translateService.instant('pages.search_resources.settings.selectAllText'),
      unSelectAllText: this.translateService.instant('pages.search_resources.settings.unSelectAllText'),
      classes: 'myclass custom-class',
      labelKey: this.translateService.instant('pages.search_resources.settings.labelKey'),
      noDataLabel: this.translateService.get('pages.search_resources.settings.noDataLabel'),
      enableSearchFilter: true,
      searchBy: ['name_en', 'name_ar', 'description_en', 'description_ar', 'contacts']
    };


    this.cmsService.getAllResource().subscribe(res => {
      this.itemList = res;
      this.itemListFiltered = res;
    });
    this.cmsService.getAllTopics().subscribe(res => this.topics = res);
  }

  // onSearch(evt: any) {
  //   console.log(evt.target.value);
  //   const filterString = evt.target.value.toUpperCase();
  //   this.itemListFiltered = this.itemList.filter((item) => {
  //     if (item.name_en && item.name_fr) {
  //       return (item.name_en.toUpperCase().indexOf(filterString) > -1 || item.name_fr.indexOf(filterString) > -1);
  //     }
  //   });
  // }
  onItemSelect(item: Resource) {
    let catI = 1;
    if (item.topics.length > 0) {
      this.topics.map((topic) => {
        if (item.topics[0] === topic.name) { catI = topic.category; }
      });
    }
    this.router.navigate(['doc-details/' + item.resource_type + '/' + catI.toString() + '/' + item.id]);
  }
  OnItemDeSelect(item: any) {
    console.log(item);
    console.log(this.selectedItems);
  }
  onSelectAll(items: any) {
    console.log(items);
  }
  onDeSelectAll(items: any) {
    console.log(items);
  }





}

import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {CmsService} from '../../services/cms.service';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {LoginService} from "../../services/login.service";

@Component({
  selector: 'app-single-category',
  templateUrl: './single-category.component.html',
  styleUrls: ['./single-category.component.scss']
})
export class SingleCategoryComponent implements OnInit {
  $category ;
  categoryId = '';
  preselectedIndicator;
  currentLang;
  constructor(private activatedRoute: ActivatedRoute, private loginService: LoginService, private cmsService: CmsService, private translateService: TranslateService) { }

  ngOnInit() {

    this.currentLang = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe(lang => {
      this.currentLang = lang.lang;
    });

    this.activatedRoute.params.subscribe(params => {
      this.categoryId = params.categoryId;
      this.$category = this.cmsService.getCategoryById(this.categoryId, this.loginService.token);
    });
  }

}

import {Component, ComponentFactoryResolver, Input, OnInit, ViewContainerRef} from '@angular/core';
import {CmsService} from '../../../services/cms.service';
import {tap} from 'rxjs/operators';
import {environment} from '@environment';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-chart-manager',
  templateUrl: './chart-manager.component.html',
  styleUrls: ['./chart-manager.component.scss']
})
export class ChartManagerComponent implements OnInit {

  @Input() chartId;

  environment = environment;

  chartConfiguration;

  currentLang = 'en';

  constructor(private viewContainerRef: ViewContainerRef,
              private cfr: ComponentFactoryResolver,
              private cmsService: CmsService
  ) { }

  ngOnInit() {

    this.cmsService.getGraphsById(this.chartId).pipe(tap(data => { if (environment.log) { /* console.log(data);*/ } })).subscribe(configuration => this.chartConfiguration = configuration);
  }


}

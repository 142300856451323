    import {LayerManager} from './layer.class';
    import * as L from 'leaflet';
    import {LayerOnMap} from '../interfaces/webgis';
    import {ApiService} from '../services/api.service';
    import {map} from 'rxjs/operators';
    import {ConfigService} from '../services/config.service';
    import {TranslateService} from "@ngx-translate/core";




    export class WmsClass extends LayerManager {

        constructor( layerOnMap: LayerOnMap, leafletMap: L.Map, apiService: ApiService, configService: ConfigService) {
            super( layerOnMap, leafletMap, apiService, configService);
        }

        load() {
            this.leafletLayer = L.tileLayer.wms(this.data_source().url,
                {
                    layers: this.layerConfig.layer_id,
                    opacity: this.layerOnMap.is_visible ? 1 : 0,
                    transparent: true,
                    format: 'image/png'
                }).addTo(this.leafletMap).bringToFront();
        }

        setBBoxLayer() {
            this.leafletMap.fitBounds([[this.layerConfig.y_min, this.layerConfig.x_min], [this.layerConfig.y_max, this.layerConfig.x_max]]);
        }

        legend() {
            return this.layerConfig.data_source.url
                + '?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LEGEND_OPTIONS=forceLabels:on&LAYER='
                + this.layerConfig.layer_id;
        }

        infoParser(data) {
        }

        getOpacity() {

            // @ts-ignore
            return this.leafletLayer.options.opacity;
        }
        setOpacity(opacity) {
            // @ts-ignore
            this.leafletLayer.setOpacity(opacity    );
        }

        toggleLayer() {
            // @ts-ignore
            if (this.leafletLayer.options.opacity !== 0) {
                this.setOpacity(0);
            } else {
                // @ts-ignore
                this.leafletLayer.setOpacity(1);
            }
        }

        hasInfoData() {
            return true;
        }

        infoData(evt, layerInstance: LayerManager) {
            return this.apiService.extGet(this.buildRequestUrl(evt)).pipe(map(data => this.parseInfo(data, layerInstance)));
        }

        parseInfo(data, layerInstance) {

            const ret = {
                layerName: (layerInstance) ? layerInstance.name('en') : 'Background Layer',
                properties : []
            };

            if (data.features && data.features.length > 0) {
                data.features.forEach( (f) => {
                    if (!f.geometry && 'GRAY_INDEX' in f.properties) {
                        ret.properties.push({name: 'value', 'value': f.properties.GRAY_INDEX.toFixed(2)});
                    } else {
                        for (const p of Object.keys(f.properties)) {
                            ret.properties.push({name: p, 'value': f.properties[p]});
                        }
                    }
                });
            }


            // console.log(ret);

            return ret;
        }

        buildRequestUrl(evt) {
            const point = this.leafletMap.latLngToContainerPoint(evt.latlng);
            const size = this.leafletMap.getSize();

            const layer: any = this.leafletLayer;
                // @ts-nocheck
            if (layer.wmsParams) {

                    let infoFormat = 'application/json';
                    if (layer.wmsParams.infoFormat) {
                        infoFormat = layer.wmsParams.infoFormat;
                    }


                    // tslint:disable-next-line:max-line-length
                    const bbox = layer.wmsParams.version === '1.3.0' ? [this.leafletMap.getBounds().getSouth(), this.leafletMap.getBounds().getWest(), this.leafletMap.getBounds().getNorth(), this.leafletMap.getBounds().getEast()].join(',') :
                        this.leafletMap.getBounds().toBBoxString();

                    const params = {
                        request: 'GetFeatureInfo',
                        service: 'WMS',
                        // srs: 'EPSG:4326',
                        // crs: 'EPSG:4326',
                        styles: layer.wmsParams.styles,
                        version: layer.wmsParams.version,
                        format: layer.wmsParams.format,
                        bbox,
                        height: size.y,
                        width: size.x,
                        layers: layer.wmsParams.layers,
                        query_layers: layer.wmsParams.layers,
                        info_format: infoFormat,
                    };
                    params[params.version === '1.3.0' ? 'i' : 'x'] = Math.round(point.x);
                    params[params.version === '1.3.0' ? 'j' : 'y'] = Math.round(point.y);
                    params[params.version === '1.3.0' ? 'crs' : 'srs'] = 'EPSG:4326';

                    // andrà nella classe wmst
                    if (layer.wmsParams.time) {
                        // @ts-ignore
                        params.time = layer.wmsParams.time;
                    }

                    const url = layer._url + L.Util.getParamString(params, layer._url, true);
                    return url;
                }
        }
    }

    export default WmsClass;

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CustomPrintService {

  itemsToPrint: any = []

  addItem(newItem) {
    this.itemsToPrint = [...this.itemsToPrint, newItem]
    console.log('Added', this.itemsToPrint)
  }

  removeItem(exItem) {
    this.itemsToPrint = this.itemsToPrint.filter ((x:any) => x!=exItem)
    console.log('Removed', this.itemsToPrint)
  }

  constructor() { }
}

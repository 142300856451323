import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@environment';
import {Resource} from '../interfaces/resource';
import {forkJoin, Observable} from 'rxjs';
import {Chart} from '../interfaces/chart';
import {map, tap} from 'rxjs/operators';
import * as _ from 'lodash';
import {Category} from '../interfaces/category';
import {Layer, LayersGroup} from '../interfaces/webgis';
import {LoginService} from "./login.service";

@Injectable({
  providedIn: 'root'
})
export class CmsService {
  private environment = environment;

  baseUrl = this.environment.apiUrl + 'cms/';
  baseUrlWebGis = this.environment.apiUrl + 'web_gis/';

  selectedLang = 'en'

  ResourcesForSearch;

  constructor(private http: HttpClient) {

  }

  /*AUTH*/
  getToken(username, password):Observable<any>{
    const params = {
      username: username,
      password: password
    }
    return this.http.post(this.environment.apiUrl + 'api-token-auth/', params)
  }
  getMe(token: string):Observable<any>{
    return this.http.get(this.environment.apiUrl + '/api/me/', {
      headers: {
        'Authorization': 'Token ' + token
      }
    })
  }

  getCategories(token?): Observable<Category[]> {
    if(!token) return this.http.get<Category[]>(this.baseUrl + 'categories/');
    // add roles to headers
    return this.http.get<any[]>(this.baseUrl + 'categories/', {
      headers: {
        'Authorization': 'Token ' + token
      }
    })
  }

  getCategoryById(categoryId, token?) {
    if(!token) return this.http.get(this.baseUrl + 'categories/' + categoryId +'/');
    return this.http.get(this.baseUrl + 'categories/' + categoryId +'/', {
      // add roles to headers
      headers: {
        'Authorization': 'Token ' + token
      }
    });
  }

  getResources(): Observable<Resource[]> {
    return this.http.get<Resource[]>(this.baseUrl + 'resources/');
  }

  getDocuments() {
  }

  getResourcesByCategoryId(categoryId): Observable<Resource[]> {
    return this.http.get<Resource[]>(this.baseUrl + 'resources/?category_id=' + categoryId);
  }

  getHtmlPages() {
    return this.http.get(this.baseUrl + 'html_pages/');
  }

  getHtmlPagesById(pageId) {
    return this.http.get(this.baseUrl + 'html_pages/' + pageId +'/');
  }

  getFullResourceByTypeAndId(type: string, id: string) {
      switch (type) {
        case 'document': return this.getDocumentById(id);
        case 'graph': return this.getGraphsById(id);
        case 'webgis': return this.getWebgisById(id);
        case 'link': return this.getLinkById(id);
        case 'story': return this.getStoryById(id);
      }
  }

  getAllResource() {
    return this.http.get<Resource[]>(this.baseUrl + 'resources/');
  }

  getAllTopics() {
    return this.http.get<Resource[]>(this.baseUrl + 'topics/');
  }

  getDocumentById(id: string) {
    return this.http.get(this.baseUrl + 'documents/' +id +'/');
  }

  getGraphsById(id: string) {
    return this.http.get<Chart>(this.baseUrl + 'graphs/' + id +'/').pipe(
        map(data => {

          return data;

        })
    );
  }



  getWebgisById(id: string) {
    return this.http.get(this.baseUrl + 'webgis/' + id +'/');
  }

  getWebgisMap(id: string) {
    return this.http.get(this.environment.apiUrl + 'web_gis/maps/?web_gis_id=' + id).pipe(map(data => {

      data[0].layers = _.groupBy(data[0].layers, (d) => { return d.layers_group.name; });

      return data[0];
    }));
  }

  getLinkById(id: string) {
    return this.http.get(this.baseUrl + 'links/' + id +'/');
  }

  getStoryById(id: string) {
    return this.http.get(this.baseUrl + 'stories/' + id +'/');
  }

  getLastResources(token?) {
    if(!token) return this.http.get(this.baseUrl + 'resources/last/');
    return this.http.get(this.baseUrl + 'resources/last/', {
      // add roles to headers
      headers: {
        'Authorization': 'Token ' + token
      }});
  }


  getLayers(): Observable<Layer[]> {
      return this.http.get<Layer[]>(this.baseUrlWebGis + 'layers/' );
  }

  getLayersGroup(): Observable<LayersGroup[]> {
    return this.http.get<LayersGroup[]>(this.baseUrlWebGis + 'layers_group/' );
  }

/*  filterCategoriesByRoles(cats, roles?) {
    if (roles && roles.includes('reporter')) {
      console.log('sono rep');
      return cats
    }
    else if(roles && roles.includes('operator')) {
      console.log('sono ope')
      return cats.filter(x=>x.group != 'reporter');
    }
    else return cats.filter(x=>x.group != 'all')
  }*/

}

import { Component, OnInit } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ChartDialogComponent} from '../../components/chart-dialog/chart-dialog.component';
import {ActivatedRoute} from '@angular/router';
import {CmsService} from '../../services/cms.service';
import {tap} from 'rxjs/operators';
import {VideoDialogComponent} from '../../components/video-dialog/video-dialog.component';

import {environment} from '@environment';
import {TranslateService} from '@ngx-translate/core';
import {LoginService} from "../../services/login.service";

@Component({
  selector: 'app-doc-details',
  templateUrl: './doc-details.component.html',
  styleUrls: ['./doc-details.component.scss']
})
export class DocDetailsComponent implements OnInit {

  environment = environment;
  documentType;
  documentId;
  categoryId;

  resource;
  resource$;
  category$;
  currentLang;

  constructor(private activatedRoute: ActivatedRoute, private loginService: LoginService,  private cmsService: CmsService , private modalService: NgbModal, private translateService: TranslateService) {}

  open() {
    const modalRef = this.modalService.open(ChartDialogComponent, {
      size: 'lg'
    });

    modalRef.componentInstance.resource = this.resource;

  }

  openVideo() {
    const modalRef = this.modalService.open(VideoDialogComponent, {
      size: 'lg'
    });

    modalRef.componentInstance.resource = this.resource;
  }

  ngOnInit() {

    this.currentLang = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe(lang => {
      this.currentLang = lang.lang;
    });

    this.activatedRoute.params.subscribe(params => {
      this.documentType = params.documentType;
      this.documentId = params.documentId;
      this.categoryId = params.categoryId;
      this.category$ = this.cmsService.getCategoryById(this.categoryId, this.loginService.token)
      this.resource$ = this.cmsService.getFullResourceByTypeAndId(this.documentType, this.documentId).pipe(tap(data => {
        if (this.environment.log)console.log(data);
        this.resource = data;
      }));

    });
  }

}





import { Component, OnInit } from '@angular/core';

import {versions} from '@versions';


@Component({
  selector: 'app-main-footer',
  templateUrl: './main-footer.component.html',
  styleUrls: ['./main-footer.component.scss']
})
export class MainFooterComponent implements OnInit {

  version;

  constructor() {

    this.version = versions;
  }

  ngOnInit() {
  }

}

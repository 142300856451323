import { Component, OnInit } from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {CustomPrintService} from "../../services/custom-print.service";

@Component({
  selector: 'app-custom-print-cart',
  templateUrl: './custom-print-cart.component.html',
  styleUrls: ['./custom-print-cart.component.scss']
})
export class CustomPrintCartComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal, public customPrintService: CustomPrintService) { }

  ngOnInit() {
  }

}

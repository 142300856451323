import {Component, EventEmitter, OnInit, Output} from '@angular/core';

import * as L from 'leaflet';
import 'leaflet.gridlayer.googlemutant';

@Component({
  selector: 'app-base-layer-chooser',
  templateUrl: './base-layer-chooser.component.html',
  styleUrls: ['./base-layer-chooser.component.scss']
})
export class BaseLayerChooserComponent implements OnInit {

  @Output() backgroundMapSelected = new EventEmitter();

  constructor() { }

  osmBasic = L.tileLayer('//{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    attribution:
        '&copy; <a href="//openstreetmap.org">OpenStreetMap</a> contributors',
    maxZoom: 24
  });
/*  osmMapquest = L.tileLayer('//otile{s}.mqcdn.com/tiles/1.0.0/osm/{z}/{x}/{y}.png', {
    subdomains: '12',
    // tslint:disable-next-line:max-line-length
    attribution: '&copy; <a href="//openstreetmap.org">OpenStreetMap</a> contributors. Tiles courtesy of <a href="//www.mapquest.com/" target="_blank">MapQuest</a> <img src="https://developer.mapquest.com/content/osm/mq_logo.png">',
    maxZoom: 24
  });
  osmHumanitarian = L.tileLayer('//{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png', {
    // tslint:disable-next-line:max-line-length
    attribution: '&copy; <a href="//openstreetmap.org">OpenStreetMap</a> contributors. Tiles courtesy of <a href="//hot.openstreetmap.org/" target="_blank">Humanitarian OpenStreetMap Team</a>',
    maxZoom: 24
  });
  osmCycle = L.tileLayer('//{s}.tile.opencyclemap.org/cycle/{z}/{x}/{y}.png', {
    // tslint:disable-next-line:max-line-length
    attribution: '&copy; <a href="//openstreetmap.org">OpenStreetMap</a> contributors. Tiles courtesy of <a href="//www.thunderforest.com/" target="_blank">Andy Allan</a>',
    maxZoom: 24
  });
  osmTransport = L.tileLayer('//{s}.tile.thunderforest.com/cycle/{z}/{x}/{y}.png', {
    // tslint:disable-next-line:max-line-length
    attribution: '&copy; <a href="//openstreetmap.org">OpenStreetMap</a> contributors. Tiles courtesy of <a href="//www.thunderforest.com/" target="_blank">Andy Allan</a>',
    maxZoom: 24
  });*/

  esriImagery = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
    attribution: 'Tiles &copy; Esri &mdash; Source: Esri',
    maxZoom: 24
  });


/*
  // @ts-ignore
  googleHybrid = L.gridLayer.googleMutant({type: 'hybrid'});
  // @ts-ignore
  googleMap = L.gridLayer.googleMutant({type: 'roadmap'});
  // @ts-ignore
  googleTerrain = L.gridLayer.googleMutant({type: 'terrain'});
  // @ts-ignore
  googleSatelite = L.gridLayer.googleMutant({type: 'satellite'});
*/

  baseLayers = [
      {name: 'OSM Basic', leafletLayer : this.osmBasic, thumb: 'assets/demo/basic.png'},
      // {name: 'OSM Cycle', leafletLayer : this.osmCycle, thumb: 'assets/demo/opencyclemap.png'},
/*      {name: 'Google Maps', leafletLayer : this.googleMap, thumb: 'assets/demo/googlemaps.png'},
      {name: 'Google Hybrid', leafletLayer : this.googleHybrid, thumb: 'assets/demo/googlehybrid.png'},
      {name: 'Google Terrain', leafletLayer : this.googleTerrain, thumb: 'assets/demo/googlehybrid.png'},
      {name: 'Google Satellite', leafletLayer : this.googleSatelite, thumb: 'assets/demo/googlehybrid.png'},*/
      {name: 'Esri Imagery', leafletLayer : this.esriImagery, thumb: 'assets/demo/esri_imagery.jpg'},
      ];

  ngOnInit() {
  }

}

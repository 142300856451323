import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {tap} from 'rxjs/operators';
import {CmsService} from '../../services/cms.service';
import {Layer} from '../../interfaces/webgis';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-magic-search',
  templateUrl: './magic-search.component.html',
  styleUrls: ['./magic-search.component.scss']
})
export class MagicSearchComponent implements OnInit {

  @Output() layerSelected = new EventEmitter();

  constructor(private cmsService: CmsService, private translateService: TranslateService) { }

  layers;
  currentLang = 'en';

  selectedLayer: Layer;

  dropdownSettings = {
    singleSelection: true,
    text: 'Sélectionnez Layer',
    selectAllText: 'Tout Sélectionner',
    unSelectAllText: 'Tout Déselectionner',
    searchPlaceholderText: 'Chercher',
    enableSearchFilter: true,
    classes: 'myclass custom-class'
  };

  layers$ = this.cmsService.getLayers().pipe(tap(data => this.layers = data));

  onItemSelect(layer) {
    console.log(layer);
  }

  ngOnInit() {
    this.currentLang = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe(lang => {
      this.currentLang = lang.lang;
    });
  }

}

import {Component, Input, OnInit, Pipe, PipeTransform} from '@angular/core';
import {CmsService} from '../../services/cms.service';
import {map, tap} from 'rxjs/operators';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss']
})
export class LinkComponent implements OnInit {

  @Input() linkResourceId;

  link$;

  constructor(private cmsService: CmsService, private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.link$ = this.cmsService.getLinkById(this.linkResourceId);
  }


}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cat-icon {
  font-size: 4em;
}

::ng-deep .inverted-layout .single-category .main-title-wrapper, ::ng-deep .inverted-layout .single-category .description-wrapper {
  flex-direction: row-reverse;
}
::ng-deep .inverted-layout .single-category .description {
  text-align: right;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/single-category/single-category.component.scss"],"names":[],"mappings":"AACA;EACE,cAAA;AAAF;;AAKI;EACE,2BAAA;AAFN;AAII;EACE,iBAAA;AAFN","sourcesContent":["@import \"../../../_global-variables\";\n.cat-icon {\n  font-size: 4em;\n}\n\n::ng-deep {\n  .inverted-layout .single-category {\n    .main-title-wrapper, .description-wrapper {\n      flex-direction: row-reverse;\n    }\n    .description {\n      text-align: right;\n    }\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

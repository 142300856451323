import {AfterViewInit, Component, Input, OnInit, SimpleChanges} from '@angular/core';
import * as ChartJs from 'chart.js';
import { v4 as uuidv4 } from 'uuid';
import {Chart} from '../../../interfaces/chart';
import {SerieService} from '../../../services/serie.service';
import * as _ from 'lodash';


@Component({
  selector: 'app-generic-chart',
  templateUrl: './generic-chart.component.html',
  styleUrls: ['./generic-chart.component.scss']
})
export class GenericChartComponent implements OnInit {

  @Input() chartObj: Chart;

  uid;
  canvas;
  ctx;
  Chart;
  series;

  constructor(private serieService: SerieService) {
    this.uid = uuidv4();
  }

  ngOnInit() {
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit() {

  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnChanges(change: SimpleChanges) {
    if (change.chartObj) {
      this.loadSeries();

    }

  }

  loadSeries() {

    this.serieService.getSerieById(this.chartObj.configuration.serie).subscribe(
        data => {
          this.series = data.data;
          console.table(data);
          this.loadChart();
        }
    );
  }


  loadChart() {
    switch (this.chartObj.type) {
      case 'pie':
        this.loadPieChart();
        break;
      case 'histogram':
        this.loadStackedChart();
        break;
    }
  }

  loadPieChart() {
    this.canvas = document.getElementById(this.uid);
    this.ctx = this.canvas.getContext('2d');
    this.Chart = new ChartJs(this.ctx, {
      type: 'pie',
      data: {
        labels: ['banana', 'bean', 'corn'],
        datasets: [{
          label: 'Tons',
          data: [59936, 15739, 25000],
          backgroundColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)'
          ],
          borderWidth: 1
        }]
      },
      options: {
        display: true
      }

    });
  }


  loadStackedChart() {

    this.canvas = document.getElementById(this.uid);
    this.ctx = this.canvas.getContext('2d');
    this.Chart = new ChartJs(this.ctx, {
      type: 'bar',
      data: {

        labels: ['Ouest',
          'Sud-Est',
          'Nord',
          'Nord-Est',
          'Artibonite',
          'Centre',
          'Sud',
          'Grande-Anse',
          'Nord-Ouest',
          'Nippes'
        ],
        datasets: [{
          label: 'corn',
          backgroundColor: '#caf270',
          data: [12, 59, 5, 56, 58, 12, 59, 87, 45],
        }, {
          label: 'mais',
          backgroundColor: '#45c490',
          data: [12, 59, 5, 56, 58, 12, 59, 85, 23],
        }, {
          label: 'bean',
          backgroundColor: '#008d93',
          data: [12, 59, 5, 56, 58, 12, 59, 65, 51],
        }, {
          label: 'banana',
          backgroundColor: '#2e5468',
          data: [12, 59, 5, 56, 58, 12, 59, 12, 74],
        }],
      },
      options: {
        tooltips: {
          displayColors: true,
          callbacks:{
            mode: 'x',
          },
        },
        scales: {
          xAxes: [{
            stacked: true,
            gridLines: {
              display: false,
            }
          }],
          yAxes: [{
            stacked: true,
            ticks: {
              beginAtZero: true,
            },
            type: 'linear',
          }]
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: { position: 'bottom' },
      }
    });

  }
}

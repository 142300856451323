import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {HomeComponent} from './pages/home/home.component';
import {SingleCategoryComponent} from './pages/single-category/single-category.component';
import {DocDetailsComponent} from './pages/doc-details/doc-details.component';
import {CategoryComponent} from './pages/category/category.component';
import {GisContainerComponent} from './pages/gis-container/gis-container.component';
import {LoginComponent} from './components/login/login.component';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {SinglePageComponent} from './pages/single-page/single-page.component';
import {CookiePageComponent} from './pages/cookie-page/cookie-page.component';
import {VideoTutorialComponent} from './pages/video-tutorial/video-tutorial.component';

import {PrintDashboardComponent} from './components/print-dashboard/print-dashboard.component';
import {SearchResultComponent} from "./pages/search-result/search-result.component";
import {CustomPrintPageComponent} from "./components/custom-print-page/custom-print-page.component";



const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'single-category/:categoryId', component: SingleCategoryComponent},
  //{ path: 'single-category-admin', component: SingleCategoryComponent, canActivate: [AppAuthGuard]},
  { path: 'category', component: CategoryComponent},
  { path: 'doc-details/:documentType/:categoryId/:documentId', component: DocDetailsComponent},
  // { path: 'doc-details:id', component: DocDetailsComponent, canActivate: [AppAuthGuard]},
  //{ path: 'doc-details-admin', component: DocDetailsComponent, canActivate: [AppAuthGuard], data: { roles: ['admin']}},
  { path: 'gis/:webgisId', component: GisContainerComponent},
  { path: 'login', component: LoginComponent},
  { path: 'gis', component: GisContainerComponent},
  { path: 'search-result', component: SearchResultComponent},
  // { path: 'la-convention-biodiv', component: SinglePageComponent},
  // { path: 'desertification', component: SinglePageComponent},
  { path: 'page/:pageId', component: SinglePageComponent},
  // { path: 'changements-climatique', component: SinglePageComponent},
  { path: 'dashboard/:categoryId', component: DashboardComponent},
  { path: 'dashboard', component: DashboardComponent},
  { path: 'print-dashboard', component: PrintDashboardComponent},
  { path: 'cookie-policy', component: CookiePageComponent},
  { path: 'video-tutorial', component: VideoTutorialComponent},
  { path: 'custom-print-page', component: CustomPrintPageComponent},

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'top', useHash: true})],
  exports: [RouterModule],
  providers: []

})
export class AppRoutingModule { }

import {Component, Input, OnInit, SimpleChanges, OnChanges} from '@angular/core';
import {CmsService} from '../../services/cms.service';
import {tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Resource} from '../../interfaces/resource';
import {FilterResourcesPipe} from '../../pipes/filter-resources.pipe';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-resources-list',
  templateUrl: './resources-list.component.html',
  styleUrls: ['./resources-list.component.scss'],
  providers: [FilterResourcesPipe]
})
export class ResourcesListComponent implements OnInit{

  @Input() category ;

  resources;
  filteredResources;

  itemPerPage = 4;

  pages;
  pageSelected = 0;
  indexOfViewing = 0;
  currentLang = 'en';


  constructor(private cmsService: CmsService, private translateService: TranslateService) {

  }

  setFilteredResource(event) {

    this.filteredResources = event.filter(x => x.resource_type !== 'story');
    this.buildPages();

  }

  ngOnInit() {
    this.currentLang = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe(lang => {
      this.currentLang = lang.lang;
    });
  }


  buildPages() {

    // tslint:disable--line:radix
    this.pages = new Array(parseInt(String(this.filteredResources.length / this.itemPerPage) ) + this.pagToAdd());
    this.pages = Array.from(this.pages.keys());

    this.selectPage(0);

  }

  pagToAdd(){
    if ((this.filteredResources.length % this.itemPerPage)==0) return 0 ; else return 1
  }

  selectPage(page){
    this.pageSelected = page;
    this.indexOfViewing = page * this.itemPerPage;
  }

  changePage(page){
    this.pageSelected = page;
    this.indexOfViewing = this.itemPerPage * page;
  }

  ngOnChanges(changes: SimpleChanges) {
    // changes.prop contains the old and the new value...

  }




}

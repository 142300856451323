// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*===============================================*/
/*============ LAST UPDATE SECTION    ===========*/
/*===============================================*/
#categories {
  padding-top: 100px;
  margin-top: -60px;
}

/*===============================================*/
/*============ SEARCH SECTION    ===========*/
/*===============================================*/
.parallax {
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

.search-section-bg {
  background-image: url('iraq-search-bg.jpg');
  background-position: center;
  height: 300px;
}

.search-wrapper {
  /*  .search-before {
      background-color: \$light;
      width: 80px;
      color: \$secondary;
    }
    .search-text-field{
      width: 100%;
    }
    #main-search-input{
      border: 0px;
      padding: 10px 15px;
      font-size: x-large;
      width: 100%;
    }
    .search-submit {
      width: 180px;
      background-color: \$primary;
      color: \$white;
      cursor: pointer;
      &:hover {
        background-color: \$dark-primary;
      }
      h4 {
        margin-bottom: 0;
      }
    }*/
  display: flex;
  justify-content: center;
}
.search-wrapper input {
  min-width: 300px;
  border-radius: 0;
  border: 1px solid grey;
}`, "",{"version":3,"sources":["webpack://./src/app/components/resources-search/resources-search.component.scss"],"names":[],"mappings":"AAIA,kDAAA;AACA,kDAAA;AACA,kDAAA;AACA;EAEE,kBAAA;EACA,iBAAA;AAJF;;AAOA,kDAAA;AACA,6CAAA;AACA,kDAAA;AACA;EACE,4BAAA;EACA,4BAAA;EACA,sBAAA;AAJF;;AAMA;EACE,2CAAA;EACA,2BAAA;EACA,aAAA;AAHF;;AAMA;EACA;;;;;;;;;;;;;;;;;;;;;;;;;MAAA;EA0BE,aAAA;EACA,uBAAA;AAHF;AAIE;EACE,gBAAA;EACA,gBAAA;EACA,sBAAA;AAFJ","sourcesContent":["@import \"../../../_global-variables\";\n\n\n\n/*===============================================*/\n/*============ LAST UPDATE SECTION    ===========*/\n/*===============================================*/\n#categories{\n  // necessario per link anchor\n  padding-top: 100px;\n  margin-top: -60px;\n}\n\n/*===============================================*/\n/*============ SEARCH SECTION    ===========*/\n/*===============================================*/\n.parallax {\n  background-attachment: fixed;\n  background-repeat: no-repeat;\n  background-size: cover;\n}\n.search-section-bg{\n  background-image: url(\"../../../assets/img/iraq-search-bg.jpg\");\n  background-position: center;\n  height: 300px;\n}\n\n.search-wrapper {\n/*  .search-before {\n    background-color: $light;\n    width: 80px;\n    color: $secondary;\n  }\n  .search-text-field{\n    width: 100%;\n  }\n  #main-search-input{\n    border: 0px;\n    padding: 10px 15px;\n    font-size: x-large;\n    width: 100%;\n  }\n  .search-submit {\n    width: 180px;\n    background-color: $primary;\n    color: $white;\n    cursor: pointer;\n    &:hover {\n      background-color: $dark-primary;\n    }\n    h4 {\n      margin-bottom: 0;\n    }\n  }*/\n  display: flex;\n  justify-content: center;\n  input {\n    min-width: 300px;\n    border-radius: 0;\n    border: 1px solid grey;\n  }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-bottom {
  background-color: #3f3d47;
  color: #ffffff;
  padding: 5px 0px;
  height: 34px;
}

::ng-deep .inverted-layout .footer-bottom-wrapper {
  flex-direction: row-reverse;
}`, "",{"version":3,"sources":["webpack://./src/app/components/main-footer/main-footer.component.scss","webpack://./src/_global-variables.scss"],"names":[],"mappings":"AAEA;EACE,yBCYa;EDXb,cCOM;EDNN,gBAAA;EACA,YCsCc;ADvChB;;AAOI;EACE,2BAAA;AAJN","sourcesContent":["@import \"../../../_global-variables\";\n\n.footer-bottom {\n  background-color: $dark-primary;\n  color: $white;\n  padding: 5px 0px;\n  height: $h-main-footer;\n}\n\n\n::ng-deep {\n  .inverted-layout {\n    .footer-bottom-wrapper {\n      flex-direction: row-reverse;\n    }\n  }\n}\n","//Custom Color\n$primary: #585463;\n$secondary:#d5c6cb;\n$success: #3a845c;\n$danger: #b85f51;\n$warning: #9d6526;\n$info: #c98942;\n$alert: #f1c73f;\n$blue: #5086bb;\n\n$black: #333333;\n$white: #ffffff;\n$light: #f8f9fa;\n\n//dark & light\n$dark-primary: darken( $primary, 10% );\n$light-primary: lighten( $primary, 10% );\n$dark-secondary: darken( $secondary, 10% );\n$light-secondary: lighten( $secondary, 10% );\n$dark-success: darken( $success, 10% );\n$light-success: lighten( $success, 10% );\n$dark-danger: darken( $danger, 10% );\n$light-danger: lighten( $danger, 10% );\n$dark-warning: darken( $warning, 10% );\n$light-warning: lighten( $warning, 10% );\n$dark-info: darken( $info, 10% );\n$light-info: lighten( $info, 10% );\n$dark-alert: darken( $alert, 10% );\n$light-alert: lighten( $alert, 10% );\n\n// Override default color\n$theme-colors: (\n  \"primary\": $primary,\n  \"secondary\": $secondary,\n  \"success\": $success,\n  \"danger\": $danger,\n  \"warning\": $warning,\n  \"info\": $info,\n  \"black\": $black\n);\n\n//\n$h-header: 80px;\n$h-toolbar: 40px;\n$h-main-footer: 34px;\n\n\n@mixin ellipsis($width: 100%) {\n  display: inline-block;\n  max-width: $width;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import {AfterViewInit, Component, Input, OnInit, SimpleChanges} from '@angular/core';
import * as ChartJs from 'chart.js';
import { v4 as uuidv4 } from 'uuid';
import {Chart} from '../../../interfaces/chart';
import {SerieService} from '../../../services/serie.service';
import {ConfigService} from '../../../services/config.service';
import {TranslateService} from '@ngx-translate/core';


@Component({
    selector: 'app-pie-chart',
    templateUrl: './pie-chart.component.html',
    styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements AfterViewInit {


    constructor(private serieService: SerieService, private configService: ConfigService, private translateService: TranslateService) {
        this.uid = uuidv4();
    }

    @Input() chartObj: Chart;

    uid;
    canvas;
    ctx;
    Chart;
    series;
    currentLang;


    parsedData;
    selectOptions;
    selectedAreas;


    backgroundColor = [];

    // tslint:disable-next-line:use-lifecycle-interface
    ngOnChanges(change: SimpleChanges) {
      if (change.chartObj) {
         this.loadSeries();

      }

    }

    ngAfterViewInit() {
        console.log('load pie chart');
        this.currentLang = this.translateService.currentLang;
        this.translateService.onLangChange.subscribe(lang => {
            this.currentLang = lang.lang;
        });
    }



    selectArea(area) {

        this.selectedAreas = area;

        this.Chart.data = {
            labels: this.series.labels,
            datasets: [{
                label: (this.chartObj.configuration.hasOwnProperty('um')) ? this.chartObj.configuration.um : '',
                data: this.series.values[area],
                backgroundColor: this.backgroundColor,
                borderWidth: 1
            }]
        };
        this.Chart.update();
    }

    canPrint() {
        return this.configService.canPrintChart();
    }
    canExportData() {
        return this.configService.canDowloadCsv();
    }
    exportData() {
        let csvContent = 'data:text/csv;charset=utf-8\n,';
        csvContent += this.series.labels.join(',') + '\n';

        try {
            csvContent += this.series.values.join(',') + '\n';
        } catch (e) {
            csvContent += this.series.values[this.selectedAreas].join(',') + '\n';
        }
        this.configService.downloadDataInCsv(csvContent , this.chartObj['name_' + this.currentLang]);
    }

    print(id) {
        this.configService.print(id , this.chartObj['name_' + this.currentLang], '#fff' );
    }


    parseData() {

        let isMultiline;

        if (Array.isArray(this.series.values[Object.keys(this.series.values)[0]])) {
            isMultiline = true;
        }
        if (isMultiline) {
            this.selectOptions = Object.keys(this.series.values);
            this.selectedAreas = this.selectOptions[0];

            this.parsedData = {
                labels: this.series.labels,
                datasets: [{
                    label: (this.chartObj.configuration.hasOwnProperty('um')) ? this.chartObj.configuration.um : '',
                    data: this.series.values[this.selectedAreas].map(d => d.toFixed(1)),
                    backgroundColor: this.backgroundColor,
                    borderWidth: 1
                }]
            };
        } else {
            this.selectOptions = [];

            this.parsedData = {
                labels: this.series.labels,
                datasets: [{
                    label: (this.chartObj.configuration.hasOwnProperty('um')) ? this.chartObj.configuration.um : '',
                    data: this.series.values.map(d => d.toFixed(1)),
                    backgroundColor: this.backgroundColor,
                    borderWidth: 1
                }]
            };
        }


    }

    loadSeriesColor() {

        if (this.chartObj.configuration.hasOwnProperty('colors')) {
            this.backgroundColor = this.chartObj.configuration.colors;
        } else {
            this.series.labels.map(serie => {
                this.backgroundColor.push('#' + (0x1000000 + (Math.random()) * 0xffffff).toString(16).substr(1, 6));
            });
        }


    }

    loadSeries() {

        this.serieService.getSerieById(this.chartObj.configuration.serie).subscribe(
            data => {
                this.series = data[0].data;
                this.loadSeriesColor();
                this.parseData();
                this.loadChart();
            }
        );
    }



    loadChart() {

        this.canvas = document.getElementById(this.uid);
        this.ctx = this.canvas.getContext('2d');

      let displayLegend: boolean = this.parsedData.datasets.length > 1

        this.Chart = new ChartJs(this.ctx, {
            type: 'pie',
            data: this.parsedData,
            options: {
                maintainAspectRatio: false,
                display: true,
                legend: {
                  position: 'right',
                  display: displayLegend
                },
                tooltips: {
                    enabled: true,
                    mode: 'nearest',
                    callbacks:  {
                        label: (tooltipItems, data) => {
                            // console.log(data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index]);
                            // console.log(this.chartObj.configuration.hasOwnProperty('um'));
                            const um = (this.chartObj.configuration.hasOwnProperty('um')) ? this.chartObj.configuration.um : '';
                            return data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index] + ' ' + um;
                        }
                }
            }
            }

        });

    }
}
